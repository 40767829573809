import React from "react";
import "./areaBox.scss";
import { Link } from "@reach/router";
//import useTour from "../../state/tour";
import { FaChevronRight } from "react-icons/fa";
export default function AreaBox({
  data = { name: "Analytical lab" },
  small,
  hover,
  link,
  noChevron = false,
  onClick = () => {},
}) {
  //  let nav = useNavigate();
  //  let { selectBuilding, setTourLevel } = useTour();
  return (
    <Link
      onClick={onClick}
      className={"areaBox " + (small ? "small " : "") + (hover ? "hover " : "")}
      to={link || "/tour/" + data.id}
    >
      <div className="title">{data.name}</div>
      <div className="image">
        <img className="boxImage" alt="a factory" src={data.img}></img>
      </div>
      {data.sub && <div className="areaSub">{data.sub}</div>}
      <div className="text">{data.teaser}</div>
      <div className="flex1"></div>
      {!noChevron && (
        <div className="chevron">
          <FaChevronRight></FaChevronRight>
        </div>
      )}
    </Link>
  );
}
