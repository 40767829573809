import React, { Suspense, useEffect } from "react";
import "./App.scss";
import { Router } from "@reach/router";

import Home from "./screens/home";
import Tour from "./screens/tour";
import Password from "./screens/password";
import Portfolio from "./screens/portfolio";
import Video from "./screens/video";
import Spacer from "./atoms/spacer/spacer";
import UnderConstruction from "./screens/underConstruction";
//2

function App() {
  useEffect(() => {
    document.oncontextmenu = function (e) {
      if (e.button === 2) {
        e.preventDefault();
        return false;
      }
    };
  }, []);
  return (
    <>
      <Suspense fallback={null}>
        <Router>
          <Password path="/"></Password>
          <Home path="/home"></Home>
          <Video path="/video/:id"></Video>
          <Portfolio path="/portfolio"></Portfolio>
          <Tour path="/tour/:building"></Tour>
          <UnderConstruction path="/construction" />
        </Router>
      </Suspense>

      <div className="mobWarn">
        <img alt="mondi logo" className="logo" src="/logo.png"></img>
        <Spacer h={48}></Spacer>
        <div className="text">
          This experience has been designed for a large screen.<br></br>
          Please switch to a desktop or tablet to enjoy this site.
        </div>
      </div>
    </>
  );
}

export default App;
