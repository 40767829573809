import { useProgress } from "@react-three/drei";
import React, { useEffect, useMemo, useState } from "react";
import "./loader.scss";
import Loading from "react-loading";
import Spacer from "../spacer/spacer";
export default function Loader({ timeout = 1000 }) {
  let { progress } = useProgress();
  let [force, setForce] = useState(true);
  let finalProgress = useMemo(() => {
    return { count: 0 };
  }, []);
  useEffect(() => {
    if (progress > finalProgress.count) {
      finalProgress.count = progress;
    }
  }, [progress, finalProgress]);
  useEffect(() => {
    let tid = setTimeout(() => {
      setForce(false);
    }, timeout);
    return () => {
      clearTimeout(tid);
    };
  }, [timeout]);
  return (
    <div className={"loader " + (force ? " " : "hidden")}>
      <img alt="mondi logo" className="logo" src="/logo_animated.webp"></img>

      <Loading
        type="spinningBubbles"
        color="black"
        width={20}
        height={20}
      ></Loading>
      <Spacer h={12} />
      <div className="text">Loading...</div>
      <div className="version">Version 0.1.6_alpha</div>
    </div>
  );
}
