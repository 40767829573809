import React from "react";
import InfoBox from "../atoms/infoBox/infoBox";
import Layout from "../atoms/layout/layout";
import UiButtons from "../atoms/uiButtons/uiButtons";
import { Guide } from "./home";

export default function UnderConstruction() {
  return (
    <Layout noMargin>
      <img
        src="/blur.png"
        alt="blurry factory render"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      ></img>
      <UiButtons isFull={false}></UiButtons>
      <div className="constText" style={{ marginRight: 300 }}>
        UNDER CONSTRUCTION
      </div>
      <div className="fakeSidebar">
        <div className="flex1"></div>
        <InfoBox
          label={"Current building"}
          img={"/imgpilot.jpeg"}
          teaser={
            "See the different processes we can test in our Pilot Plant to provide your product efficiently, cost effectively and with the highest quality."
          }
          text={
            "The Analytical Laboratories are an integral part of the Flexible Packaging Research & Development organisation with a competent team of highly skilled experts. Our state-of-the-art analytical facilities are focused and specialised on the characterisation of polymeric materials. Equipment coming from the latest generation allows us to perform detailed and complex product and material analyses. In collaboration with our customers we also develop tailor-made test methods to suit individual needs."
          }
          name={"Flexible Production"}
        ></InfoBox>
        <div className="regal">
          <div className="name">See how our products are made.</div>
          <div className="text">
            Choose a product and follow the production line path.
          </div>
          <div className="icons">
            <div className={"icon"}>
              <img src="/icon1.png" alt="" />
            </div>
            <div className={"icon"}>
              <img src="/icon2.png" alt="" />
            </div>
            <div className={"icon"}>
              <img src="/icon3.png" alt="" />
            </div>
            <div className={"icon"}>
              <img src="/icon1.png" alt="" />
            </div>
          </div>
        </div>
        <Guide>„This tour is currently under construction.“</Guide>
      </div>
    </Layout>
  );
}
