import React from "react";
import { FaChevronLeft, FaTimes } from "react-icons/fa";
import Spacer from "../spacer/spacer";
import "./Modal.scss";

export default function Modal({
  children,
  onClose,
  type,
  id,
  url,
  text,
  invis = false,
}) {
  //let { renderModal, modalOpen, set } = useModal();

  // if (!modalOpen) {
  //   return null;
  // }
  return (
    <div className={"Modal " + (invis ? "invis " : " ")}>
      <div
        className="closeModalPlane"
        onClick={() => {
          onClose();
        }}
      ></div>
      <div className="modalInner">
        <div
          className="closeModalButton"
          onClick={() => {
            onClose();
          }}
        >
          <FaTimes></FaTimes>
        </div>
        {type === "pdf" && (
          <>
            <div class="embed-container">
              <div className="media-container">
                <iframe
                  title="Mondi Video"
                  src={url}
                  frameborder="0"
                  style={{ height: "80vh", width: "80vw" }}
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
                <div className="vidInfo">
                  <div
                    className="backButton"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <FaChevronLeft></FaChevronLeft>
                    <Spacer w={12}></Spacer> Back
                  </div>
                  <div className="text">{text}</div>
                </div>
              </div>
            </div>
          </>
        )}
        {type === "vimeo" && (
          <div class="embed-container">
            <div className="media-container">
              <iframe
                title="Mondi Video"
                src={
                  "https://player.vimeo.com/video/" +
                  id +
                  (id&&id.includes("?") ? "&" : "?") +
                  "title=0&byline=0&portrait=0"
                }
                width={1280}
                height={720}
                frameborder="0"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
              <div className="vidInfo">
                <div
                  className="backButton"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <FaChevronLeft></FaChevronLeft>
                  <Spacer w={12}></Spacer> Back
                </div>
                <div className="text">{text}</div>
              </div>
            </div>
          </div>
        )}

        {type === "image" && (
          <div class="embed-container">
            <div className="media-container">
              <img alt="factory" src={url}></img>
              <div className="vidInfo">
                <div
                  className="backButton"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <FaChevronLeft></FaChevronLeft>
                  <Spacer w={12}></Spacer> Back
                </div>
                <div className="text">{text}</div>
              </div>
            </div>
          </div>
        )}

        {children}
      </div>
    </div>
  );
}
