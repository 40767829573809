import { useParams } from "@reach/router";
import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";
import UiButtons from "../atoms/uiButtons/uiButtons";
import "./video.scss";
const videos = {
  0: {
    id: "534372900",
    text: "Text1",
  },
  1: {
    id: "534364901",
    text: "Mondi face mask production",
  },
};

export default function Video() {
  let { id } = useParams();

  return (
    <Layout noMargin>
      <div class="embed-container">
        <div className="media-container">
          <iframe
            title="Mondi Video"
            src={
              "https://player.vimeo.com/video/" +
              videos[id].id +
              "?title=0&byline=0&portrait=0"
            }
            width={1280}
            height={720}
            frameborder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
          <div className="vidInfo">
            <div
              className="backButton"
              onClick={() => {
                window.history.back();
              }}
            >
              <FaChevronLeft></FaChevronLeft>
              <Spacer w={12}></Spacer> Back
            </div>
            <div className="text">{videos[id].text}</div>
          </div>
        </div>
      </div>
      <UiButtons isFull={false}></UiButtons>
    </Layout>
  );
}
