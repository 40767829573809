import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import Layout from "../atoms/layout/layout";
import Modal from "../atoms/Modal/Modal";
import Spacer from "../atoms/spacer/spacer";
import UiButtons from "../atoms/uiButtons/uiButtons";
import { Guide } from "./home";
import "./portfolio.scss";
export default function Portfolio() {
  let [modal, setModal] = useState(false);
  return (
    <Layout noMargin>
      {modal && (
        <Modal
          onClose={() => {
            setModal(false);
          }}
          {...modal}
        ></Modal>
      )}
      <div className="portfolio" style={{ overflowY: "auto" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "0 auto",
            justifyContent: "center",

            marginTop: 120,
            minWidth: 900,
            maxWidth: 1400,
          }}
        >
          <PortfolioBox
            small
            noChevron
            link={"/video/1"}
            data={{
              id: 5,
              img: "/p3.png",
              name: "FACE MASK PRODUCTION",
              sub: "Own Masks and their Productionline",
              teaser:
                "With Mondis new mask production we set high industry standards for masks made in Germany. Mondi delivers best quality for all customers needs. Learn more of our Products in the information linked below.",
            }}
            buttons={
              <div className="buttons">
                <div
                  onClick={() =>
                    setModal({
                      type: "vimeo",
                      id: "534364901",
                      invis: true,
                      text: "Mondi Facemask production",
                    })
                  }
                  className="button"
                >
                  Video
                </div>
                <Spacer w={12}></Spacer>
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/pdf/facemask2.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>
          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 3,
              img: "/p2.png",
              name: "FUNCTIONAL BARRIER PAPER",
              sub: "New recyclable rolled goods portfolio",
              teaser:
                "Extruding, laminating, printing and cutting the barrier paper to your special needs. Mondi delivers best quality for all customers needs. Learn more of our Products in the information linked below.",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/pdf/funbp.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>

          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 4,
              img: "/barrier.jpeg",
              name: "BARRIER PACK RECYCLABLE",
              sub: "Sustainable monomaterial solutions",
              teaser:
                "Mondi developed a new award-winning flexible plastic packaging designed for a circular economy. BarrierPack Recyclable is a leap forward in making recyclable flexible plastic packaging a reality. Mondi delivers best quality for all customers needs. Learn more of our Products in the information linked below.",
            }}
            buttons={
              <div className="buttons">
                <div
                  onClick={() =>
                    setModal({
                      type: "vimeo",
                      id: "672758315?h=52c8744fc2",
                      invis: true,
                      text: "BarrierPack Recyclable animation",
                    })
                  }
                  className="button"
                >
                  Video
                </div>
                <Spacer w={12}></Spacer>
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/pdf/barrier.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>
          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 6,
              img: "/p4.jpeg",
              name: "Metal-free",
              sub: "Sustainable crunch with high-barrier protection",
              teaser:
                "This high-barrier protection laminate is a mono-material, which is recyclable where recycling streams are in place when replacing the metalized OPP layer",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/f4.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>

          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 6,
              img: "/p5.jpeg",
              name: "FlexiBag Recyclable",
              sub: "Premium recyclable and reclosable plastic bag with convenience",
              teaser:
                "Mondi developed a side gusset bag that meets the highest demands for convenient packaging while still being recyclable where recycling streams are in place.",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/f5.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>

          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 6,
              img: "/p6.jpeg",
              name: "FFS with PCR Content",
              sub: "Going back to the source",
              teaser:
                "Replaces high share of virgin polyolefin in laminates by using recycled content from selected and qualified recycling sources.",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/f6.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>

          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 7,
              img: "/inco_pads.jpg",
              name: "Inco pads",
              sub: "Going back to the source",
              teaser:
                "Replaces high share of virgin polyolefin in laminates by using recycled content from selected and qualified recycling sources.",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/f6.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>

          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 8,
              img: "/360_pants.png",
              name: "360 pants",
              sub: "Going back to the source",
              teaser:
                "Replaces high share of virgin polyolefin in laminates by using recycled content from selected and qualified recycling sources.",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/f6.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>

          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 7,
              img: "/adult_diapers.jpg",
              name: "Adult diapers",
              sub: "Going back to the source",
              teaser:
                "Replaces high share of virgin polyolefin in laminates by using recycled content from selected and qualified recycling sources.",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/f6.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>

          <PortfolioBox
            small
            noChevron
            link={"/portfolio"}
            data={{
              id: 8,
              img: "/baby_diapers.png",
              name: "Baby diapers",
              sub: "Going back to the source",
              teaser:
                "Replaces high share of virgin polyolefin in laminates by using recycled content from selected and qualified recycling sources.",
            }}
            buttons={
              <div className="buttons">
                <div
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                  onClick={() => {
                    setModal({
                      invis: true,
                      type: "pdf",
                      url: "/f6.pdf",
                    });
                  }}
                >
                  Factsheet
                </div>
              </div>
            }
          ></PortfolioBox>
        </div>
      </div>
      <div
        className="hideOnMobile"
        style={{ position: "absolute", bottom: 20, right: 20 }}
      >
        <Guide>
          „Hello! Here you can find a preview of the products we offer. This
          page is still under construction. We will give you here an overview of
          all the products we produce at Mondi.“
        </Guide>
      </div>

      <UiButtons isFull={false}></UiButtons>
    </Layout>
  );
}

function PortfolioBox({
  data = { name: "Analytical lab" },
  small,
  hover,
  link,
  noChevron = false,
  onClick = () => {},
  buttons,
}) {
  //  let nav = useNavigate();
  //  let { selectBuilding, setTourLevel } = useTour();
  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: 12 }}>
      <div
        onClick={onClick}
        className={
          "portfolioBox " + (small ? "small " : "") + (hover ? "hover " : "")
        }
        //to={link || "/tour/" + data.id}
      >
        <div className="title">{data.name}</div>
        <div className="image">
          <img className="boxImage" alt="a factory" src={data.img}></img>
        </div>
        {data.sub && <div className="areaSub">{data.sub}</div>}
        <div className="text">{data.teaser}</div>
        <div className="flex1"></div>
        {!noChevron && (
          <div className="chevron">
            <FaChevronRight></FaChevronRight>
          </div>
        )}
      </div>
      {buttons}
    </div>
  );
}
