/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import useTourState from "../../state/tour";
import useHoverState from "../../state/hoverstate";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/gltf/PP_21_11_01_512.glb",
    "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
  );
  const { actions } = useAnimations(animations, group);
  let { selectedPoint } = useTourState();
  let { currentHover } = useHoverState();
  let [line, setLine] = useState(0);
  useEffect(() => {
    if (currentHover > 0) {
      setLine((l) => l + 1);
    }
  }, [currentHover]);
  //console.log(actions);
  useEffect(() => {
    actions["animation_line_02"].setDuration(3);
    actions["animation_line_01"].setDuration(3);
    actions["animation_line_02"].play();
    actions["animation_line_01"].play();
  }, [actions]);

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        name="animated_line_02"
        scale={currentHover > 0 && line % 2 === 0 ? 1 : 0}
        geometry={nodes.animated_line_02.geometry}
        material={materials.line_red}
      />
      <mesh
        name="animated_line_01"
        scale={currentHover > 0 && line % 2 === 1 ? 1 : 0}
        geometry={nodes.animated_line_01.geometry}
        material={materials.line_yellow}
      />
      <mesh
        geometry={nodes.Cube022.geometry}
        material={nodes.Cube022.material}
        position={[72.363868713379, 2.027682542801, 68.883682250977]}
      />

      {selectedPoint <= 11 ? (
        <mesh
          geometry={nodes.castfilm_1.geometry}
          material={nodes.castfilm_1.material}
          position={[58.181884765625, 2.414881229401, 51.340362548828]}
        />
      ) : selectedPoint === 12 ? (
        <mesh
          geometry={nodes.castfilm_2.geometry}
          material={nodes.castfilm_2.material}
          position={[58.170261383057, 2.412911176682, 51.323394775391]}
        />
      ) : selectedPoint === 13 ? (
        <mesh
          geometry={nodes.castfilm_3.geometry}
          material={nodes.castfilm_3.material}
          position={[58.160697937012, 2.41420674324, 51.387573242188]}
        />
      ) : (
        <mesh
          geometry={nodes.castfilm_4.geometry}
          material={nodes.castfilm_4.material}
          position={[58.151794433594, 2.422712087631, 51.367561340332]}
        />
      )}
    </group>
  );
}
