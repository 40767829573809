import React from "react";
import { FaChevronRight } from "react-icons/fa";
import "./layout.scss";

export default function Layout({ children, noMargin, style, noMember }) {
  return (
    <div className={"inner " + (noMargin ? "noMargin " : " ")} style={style}>
      {false && !noMember && (
        <div className="memberLogin">
          member login{" "}
          <FaChevronRight style={{ paddingLeft: 12 }}></FaChevronRight>
        </div>
      )}
      {children}
      <img alt="mondi logo" className="logo" src="/logo.png"></img>
    </div>
  );
}
