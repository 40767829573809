import create from "zustand";

const useZoom = create((set, get) => ({
  currentZoom: 100,
  set: (d) => {
    set(d);
  },
}));

export default useZoom;
