import create from "zustand";

const useHoverState = create((set, get) => ({
  currentHover: null,
  setCurrentHover: (hover) => {
  
      set({ currentHover: hover });
  
  },
}));

export default useHoverState;
