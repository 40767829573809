import React from "react";
import "./form.scss";

export function Form() {
  return <div className="form"></div>;
}

export function RoundButton({ children = "Button", onClick }) {
  return (
    <div className="roundButton" onClick={onClick}>
      {children}
    </div>
  );
}

export function Selector({ options = [] }) {
  return (
    <select className="selector">
      {options.map((o) => {
        return <option key={o.key}>{o.label}</option>;
      })}
    </select>
  );
}

export function Input({ value, onChange, type = "text", ...props }) {
  return (
    <input
      className={"input"}
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    ></input>
  );
}
