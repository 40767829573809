//add an optional close button to infobox

import React, { useState } from "react";
import "./infoBox.scss";
import { FaTimes } from "react-icons/fa";
export default function InfoBox({
  noImage = false,
  label = "",
  name = "",
  text = "",
  teaser = "",
  img = "/img.png",
  sub = "",
  onClose = () => {},
  modal = () => {},
  setModal,
}) {
  let [more, setMore] = useState(false);
  return (
    <div className="infoBox">
      <div className="label">{label}</div>
      <div className="name">{name}</div>
      {!noImage && !!img && (
        <div className="image">
          <img className="img" src={img} alt="" />
        </div>
      )}
      <div className="subb">{sub}</div>
      <div className="text">
        {teaser}{" "}
        {!more && !!text && (
          <span onClick={() => setMore(true)} className="readmore">
            Read more...
          </span>
        )}
        {more && text}
      </div>
      {onClose && (
        <div className="closeButton" onClick={onClose}>
          <FaTimes></FaTimes>
        </div>
      )}
      {setModal && <div className="factsheetlink" onClick={() => setModal(modal)}>Factsheet</div>}
    </div>
  );
}
