import create from "zustand";

const useModal = create((set) => ({
  modalOpen: false,
  set: (d) => set(d),
  renderModal: () => {
    return <div>modal lol</div>;
  },
  close: () => {
    set({ modalOpen: false });
  },
}));

export default useModal;
