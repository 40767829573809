import React, { useEffect } from "react";
import Layout from "../atoms/layout/layout";
import Loader from "../atoms/loader/loader";
import Modal from "../atoms/Modal/Modal";
import TourViewer from "../atoms/tourViewer/tourViewer";
import useModal from "../state/modal";
import useTourState from "../state/tour";

export default function Tour() {
  let modalOpen = useModal((s) => s.modalOpen);
  let { reset } = useTourState();
  useEffect(() => {
    reset();
  }, [reset]);
  return (
    <Layout noMargin>
      {modalOpen && <Modal></Modal>}
      <TourViewer></TourViewer>
      <Loader timeout={2000}></Loader>
    </Layout>
  );
}
