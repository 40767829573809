import { useNavigate } from "@reach/router";
import React, { useState } from "react";
import {
  FaCompressArrowsAlt,
  FaExclamationCircle,
  FaExpandArrowsAlt,
  FaHome,
  FaMinus,
  FaPlus,
  FaSearch,
} from "react-icons/fa";
import useZoom from "../../state/zoom";
import { useTour } from "../factoryCanvas/factoryCanvas";
import Spacer from "../spacer/spacer";
import "./uiButtons.scss";

export default function UiButtons({
  openFullScreen,
  closeFullScreen,
  isFull,
  uiVisible,
  setUiVisible,
  hideBack = false,
  zoom = false,
  showSearch = false,
}) {
  let nav = useNavigate();
  let { selectPoint, building } = useTour();
  let { currentZoom, set } = useZoom();
  let [search, setSearch] = useState("");
  return (
    <div className="uiButtons">
      {!hideBack && (
        <div
          className="uiButton"
          onClick={() => {
            selectPoint(-1);
            nav("/home");
          }}
        >
          <FaHome></FaHome>
          <Spacer w={12}></Spacer>
          Back to Overview
        </div>
      )}
      {openFullScreen && closeFullScreen && (
        <>
          {!isFull ? (
            <div className="uiButton" onClick={openFullScreen}>
              <FaExpandArrowsAlt />
              <Spacer w={12}></Spacer>
              Enter Fullscreen
            </div>
          ) : (
            <div className="uiButton" onClick={closeFullScreen}>
              <FaCompressArrowsAlt />
              <Spacer w={12}></Spacer>
              Exit Fullscreen
            </div>
          )}
        </>
      )}
      {false && setUiVisible && (
        <>
          {uiVisible ? (
            <div
              className="uiButton"
              onClick={() => {
                setUiVisible(!uiVisible);
              }}
            >
              <FaExclamationCircle />
              <Spacer w={12}></Spacer>
              Hide Interface
            </div>
          ) : (
            <div
              className="uiButton"
              onClick={() => {
                setUiVisible(!uiVisible);
              }}
            >
              <FaExclamationCircle />
              <Spacer w={12}></Spacer>
              Show Interface
            </div>
          )}
        </>
      )}
      {showSearch && (
        <div className="uiButton noHover">
          <FaSearch></FaSearch>
          <Spacer w={12}></Spacer>
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearch("");
                selectPoint(
                  building.points.find((p) =>
                    (p.name + " " + p.description)
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ).id
                );
              }
            }}
          ></input>
          {search && (
            <div className="popover">
              {building.points
                .filter((p) =>
                  (p.name + " " + p.description)
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map((p) => {
                  return (
                    <div
                      onClick={() => {
                        setSearch("");
                        selectPoint(p.id);
                      }}
                      className="searchRes"
                    >
                      {p.name}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
      {zoom && (
        <>
          <div style={{ flex: 1 }}></div>
          <div
            className="uiButton"
            onClick={() => {
              set({ currentZoom: currentZoom + 20 });
            }}
          >
            <FaPlus size={16} />
          </div>
          <div
            className="uiButton"
            onClick={() => {
              set({ currentZoom: currentZoom - 20 });
            }}
          >
            <FaMinus size={16}></FaMinus>
          </div>
        </>
      )}
    </div>
  );
}
