import { Canvas } from "@react-three/fiber";
import React, { Suspense, useEffect, useState } from "react";
import "./factoryCanvas.scss";
import * as THREE from "three";
import { Html } from "@react-three/drei";
//import { Stage } from "@react-three/drei/core/Stage";

import useTourState from "../../state/tour";
import { useParams } from "@reach/router";
import Raum3 from "../gltf/Raum3";
import Raum5 from "../gltf/Raum5";
import Pilot from "../gltf/Pilot";
import {
  FaAngleLeft,
  FaChevronLeft,
  FaChevronRight,
  FaPlay,
  FaTimes,
} from "react-icons/fa";
import Controller from "./controller";
import { usePageVisibility } from "react-page-visibility";
import useZoom from "../../state/zoom";
import Modal from "../Modal/Modal";

export function useTour() {
  let b = useParams();
  b = b.building;
  let { buildings, selectPoint, selectedPoint, mode } = useTourState();
  let building = buildings[b];
  let point = null;
  let room = null;
  if (selectedPoint !== -1) {
    point = building.points[selectedPoint % building.points.length];
    room = building.rooms[point.room];
  }

  return { building, selectPoint, point, selectedPoint, room, mode };
}

export default function FactoryCanvas({ floating = false }) {
  let { building, selectedPoint, selectPoint, point } = useTour();
  let points = building.points || [];
  let [pv, setPv] = useState(false);
  const isVisible = usePageVisibility();

  let [modal, setModal] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPv(true);
    }, 5000);
  }, []);
  return (
    <>
      {modal && <Modal onClose={() => setModal(false)} {...modal}></Modal>}
      <div className={"canvasWrapper " + (floating ? "floating " : " ")}>
        <div className="floatShadow"></div>
        <Canvas
          orthographic
          camera={{
            zoom: 20,
            near: -10,
            position: new THREE.Vector3(6, 6, 6),
          }}
          style={{ width: "100%", height: "100%" }}
        >
          <fog attach="fog" args={["white", 80, 90]} />
          <Suspense fallback={null}>
            <group>
              {building && building.model === "Raum3" && (
                <Raum3 scale={0.5}></Raum3>
              )}
              {building && building.model === "Raum5" && <Raum5></Raum5>}
              {building && building.model === "Pilot" && (
                <Pilot scale={0.5}></Pilot>
              )}
              {pv && (
                <Points
                  setModal={setModal}
                  machine={point ? point.machine : null}
                  room={point ? point.room : null}
                  points={points}
                  building={building.id}
                  selectedPoint={selectedPoint}
                  selectPoint={(id, mode) =>
                    selectPoint(id % points.length, mode)
                  }
                ></Points>
              )}
            </group>
            <ambientLight intensity={0.6}></ambientLight>
            <directionalLight
              intensity={0.4}
              position={[-10, 10, 10]}
            ></directionalLight>
            <directionalLight
              intensity={1}
              position={[10, 10, -10]}
            ></directionalLight>
            <Controller
              isVisible={isVisible}
              debug={false}
              doReset={point && point.position ? true : !pv ? true : false}
              zoom={
                point ? point.zoom : building.camera ? building.camera.zoom : 50
              }
              target={
                point
                  ? point.position
                  : building.camera
                  ? building.camera.position
                  : [0, 3, 0]
              }
              targetString={
                point
                  ? point.position.join(",")
                  : building.camera
                  ? building.camera.position.join(",")
                  : "0,0,0"
              }
              targetPhi={
                point
                  ? point.phi % (Math.PI * 2)
                  : building.camera
                  ? building.camera.phi
                  : 1.213440928954453
              }
              targetTheta={
                point
                  ? point.theta
                  : building.camera
                  ? building.camera.theta
                  : 1.751411627542472
              }
            />
          </Suspense>
        </Canvas>
      </div>
    </>
  );
}

function Points({
  points = [],
  selectedPoint,
  selectPoint,
  room,
  machine,
  building,
  setModal,
}) {
  //console.log({ room });
  //console.log(points.filter((p) => !p.isRoom && p.room === room));
  return (
    <>
      {points
        .slice()
        .reverse()
        .map((p) => {
          return (
            <Point
              setModal={setModal}
              machine={machine}
              building={building}
              points={points}
              data={p}
              active={selectedPoint === p.id}
              selectPoint={selectPoint}
              room={room}
            ></Point>
          );
        })}
    </>
  );
}

function Point({
  data,
  active,
  selectPoint,
  room,
  machine,
  points,
  building,
  setModal,
}) {
  let tipHidden = useTourState((s) => s.tipHidden);
  //let fullSet = useTourState((s) => s.fullSet);
  let mode = useTourState((s) => s.mode);

  let { currentZoom } = useZoom();
  if (data.isRoom) {
  }
  if (data.noDot) {
    return null;
  }
  //console.log({ building });
  let hideIcon = false;
  hideIcon = (active && data.isRoom) || hideIcon;
  hideIcon = (data.hideIcon && !active) || hideIcon;
  hideIcon = (data.isRoom && data.room === room) || hideIcon;
  hideIcon =
    !(
      building !== 2 ||
      !(data.isMachine && data.machine === machine && data.childCount > 1)
    ) || hideIcon;

  hideIcon = (room && room !== data.room) || hideIcon;
  //hideIcon = (machine && machine !== data.machine) || hideIcon;

  hideIcon = (data.isMachine && room !== data.room) || hideIcon;
  let isMachinePart = !data.isMachine && !data.isRoom;
  hideIcon = (isMachinePart && room !== data.room) || hideIcon;
  hideIcon = (isMachinePart && machine !== data.machine) || hideIcon;
  let offset = (data && data.offset) || { left: 0, top: 0 };
  return (
    <group position={data.position}>
      <Html zIndexRange={[0, 0]}>
        {!hideIcon && (
          <div
            className={
              "circleButton " +
              (active ? "active " : " ") +
              (data.isRoom ? "isRoom " : "")
            }
            onClick={() => selectPoint(data.id)}
          >
            {data.modal && data.modal.type==="vimeo"?<FaPlay size={18}></FaPlay>:"i"}
          </div>
        )}
        {active && !tipHidden && (data.name || data.description) && (
          <div
            style={{
              fontSize: currentZoom + "%",
              width: currentZoom * 2.5 + "px",
              left: -currentZoom * 2.5 - 70 + offset.left + "px",
              bottom: 200 - currentZoom + -offset.top + "px",
            }}
            className={"tooltip " + (data.isRoom ? "bigTooltip " : " ")}
          >
            <div
              className="closeTooltip"
              onClick={() => {
                if (mode === "tour") {
                  //console.log("leave tour");
                  return selectPoint(-1, "explore");
                }
                // x / back hit
                if (!data.isMachine && !data.isRoom) {
                  //go back up to machine
                  let homePoint = points.find(
                    (p) => p.isMachine && p.machine === data.machine
                  );
                  return selectPoint(homePoint.id);
                }
                if (data.isMachine) {
                  //go up to room
                  let homePoint = points.find(
                    (p) => p.isRoom && p.room === data.room
                  );
                  return selectPoint(homePoint.id);
                }

                return selectPoint(-1);
              }}
            >
              {mode === "tour" || data.isRoom ? (
                <FaTimes></FaTimes>
              ) : (
                <FaAngleLeft></FaAngleLeft>
              )}
            </div>
            <div className="ttTitle">{data.name}</div>
            {data.modal && (
              <div
                className="ttImage"
                style={{ height: currentZoom * 1.5 }}
                onClick={() => {
                  if (data.modal) {
                    setModal(data.modal);
                  }
                }}
              >
                <img src={data.img} alt={data.name} className="img" />
              </div>
            )}
            <div className="ttText">{data.description}</div>
            {data.textModal && (
              <div
                className="textModalButton"
                onClick={() => setModal(data.textModal)}
              >
                {data.textModal.label}
              </div>
            )}
            {building === 2 && data.id > 9 && data.id < 15 && (
              <div className="steps">
                <div
                  className={"prevStep " + (data.id === 10 ? "disabled" : " ")}
                  onClick={() => {
                    data.id !== 10 ? selectPoint(data.id - 1) : selectPoint(14);
                  }}
                >
                  <FaChevronLeft />
                </div>
                <div
                  className={"nextStep " + (data.id === 14 ? "disabled" : " ")}
                  onClick={() =>
                    data.id !== 14 ? selectPoint(data.id + 1) : selectPoint(10)
                  }
                >
                  <FaChevronRight></FaChevronRight>
                </div>
              </div>
            )}
          </div>
        )}
      </Html>
    </group>
  );
}

/*
const Building = ({ data }) => {
  let ref = useRef();
  let [hovered, setHovered] = useState(false);
  return (
    <group scale={new THREE.Vector3(data.scale, data.scale, data.scale)}>
      {Turm[data.model]()}
    </group>
  );
};
*/
