import React from "react";
import AreaBox from "../atoms/areaBox/areaBox";
import Layout from "../atoms/layout/layout";
import UiButtons from "../atoms/uiButtons/uiButtons";
import useTour from "../state/tour";
import "./home.scss";

export default function Home() {
  let { buildings } = useTour();
  
  return (
    <Layout noMargin style={{ alignItems: "center", justifyContent: "center" }}>
      <UiButtons hideBack isFull={false}></UiButtons>
      <div className="boxes">
        <AreaBox hover data={buildings[2]}></AreaBox>
        <AreaBox hover data={buildings[0]}></AreaBox>
        <AreaBox hover data={buildings[1]}></AreaBox>
        <AreaBox
          hover
          data={{
            id: 5,
            img: "/imgana.jpeg",
            name: "Growing Product Portfolio",
            sub: "What we already Manufacture",
            teaser:
              "Get an overview of products that we already manufacture at the push of a button as a service provider for direct sales or whitelabeling including our brand new Mask-Production.",
          }}
          link={"/portfolio"}
        ></AreaBox>
      </div>
      <div className="sub">
        Experience how we care of your products from scratch.
      </div>
      <div style={{ position: "absolute", right: 20, bottom: 20 }}>
        <Guide>
          <div>
            „Hello, I’m Lisa, your interactive Mondi-Tourguide! Please{" "}
            <b>choose one</b> of the possible experiences above!“
          </div>
        </Guide>
      </div>
    </Layout>
  );
}

export function Guide({ children }) {
  return (
    <div className="guideContainer">
      <div className="guideText">
        <img
          alt="assistant"
          className="assistantImage"
          src="/assistant.png"
        ></img>
        {children}
      </div>
    </div>
  );
}
