import React, { useState } from "react";
import FactoryCanvas from "../factoryCanvas/factoryCanvas";
import TourController from "../tourController/tourController";
import UiButtons from "../uiButtons/uiButtons";
import "./tourViewer.scss";

export default function TourViewer() {
  let [isFull, setFull] = useState(false);
  let [uiVisible, setUiVisible] = useState(false);

  const setFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Safari */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE11 */
      document.documentElement.msRequestFullscreen();
    }
    setFull(true);
  };

  const closeFullScreen = () => {
    try {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
    } catch (e) {}

    setFull(false);
  };

  return (
    <div className="tourViewer">
      <FactoryCanvas floating={!uiVisible}></FactoryCanvas>
      <TourController floating={!uiVisible}></TourController>
      <UiButtons
        zoom
        showSearch
        isFull={isFull}
        closeFullScreen={() => {
          closeFullScreen();
        }}
        openFullScreen={() => {
          setFullScreen();
        }}
        uiVisible={uiVisible}
        setUiVisible={setUiVisible}
      ></UiButtons>
    </div>
  );
}
