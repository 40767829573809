import React, { useCallback, useEffect, useState } from "react";
//import useTour from "../../state/tour";
import InfoBox from "../infoBox/infoBox";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { animated, useSpring } from "react-spring";
import "./tourController.scss";

import { useTour } from "../factoryCanvas/factoryCanvas";
import { Guide } from "../../screens/home";
import Spacer from "../spacer/spacer";
import useHoverState from "../../state/hoverstate";
import Modal from "../Modal/Modal";

const regalData = {
  2: {
    1: {
      img: "/p6.jpeg",
      modal: {
        type: "pdf",
        url: "/f6.pdf",
        invis: true,
      },
      name: "FFS with PCR Content",
      sub: "Going back to the source",
      teaser:
        "Replaces high share of virgin polyolefin in laminates by using recycled content from selected and qualified recycling sources.",
    },
    2: {
      img: "/p5.jpeg",
      modal: {
        type: "pdf",
        url: "/f5.pdf",
        invis: true,
      },
      name: "FlexiBag Recyclable",
      sub: "Premium recyclable and reclosable plastic bag with convenience",
      teaser:
        "Mondi developed a side gusset bag that meets the highest demands for convenient packaging while still being recyclable where recycling streams are in place.",
    },
    3: {
      img: "/p4.jpeg",
      modal: {
        type: "pdf",
        url: "/f4.pdf",
        invis: true,
      },

      name: "Metal-free",
      sub: "Sustainable crunch with high-barrier protection",
      teaser:
        "This high-barrier protection laminate is a mono-material, which is recyclable where recycling streams are in place when replacing the metalized OPP layer",
    },
    4: {
      img: "/p3.png",
      modal: {
        type: "pdf",
        url: "/pdf/facemask2.pdf",
        invis: true,
      },
      name: "FACE MASK PRODUCTION",
      sub: "Own Masks and their Productionline",
      teaser:
        "With Mondis new mask production we set high industry standards for masks made in Germany. Mondi delivers best quality for all customers needs. Learn more of our Products in the information linked below.",
    },
    5: {
      img: "/p2.png",
      modal: {
        type: "pdf",
        url: "/pdf/funbp.pdf",
        invis: true,
      },
      name: "Functional barrier paper",
      sub: "New recyclable rolled goods portfolio",
      teaser:
        "Extruding, laminating, printing and cutting the barrier paper to your special needs. Mondi delivers best quality for all customers needs. Learn more of our Products in the information linked below.",
    },
    6: {
      img: "/p1.jpeg",
      modal: {
        type: "pdf",
        url: "/pdf/barrier.pdf",
        invis: true,
      },
      name: "Barrier pack recyclable",
      sub: "Sustainable monomaterial solutions",
      teaser:
        "Mondi developed a new award-winning flexible plastic packaging designed for a circular economy. BarrierPack Recyclable is a leap forward in making recyclable flexible plastic packaging a reality. Mondi delivers best quality for all customers needs. Learn more of our Products in the information linked below.",
    },
  },
  1: {
    1: {
      img: "/inco_pads.jpg",
      modal: {
        type: "pdf",
        url: "/f6.pdf",
        invis: true,
      },
      name: "Inco pads",
      sub: "Going back to the source",
      teaser:
        "Replaces high share of virgin polyolefin in laminates by using recycled content from selected and qualified recycling sources.",
    },
    2: {
      img: "/360_pants.png",
      modal: {
        type: "pdf",
        url: "/f5.pdf",
        invis: true,
      },
      name: "360 Pants",
      sub: "Premium recyclable and reclosable plastic bag with convenience",
      teaser:
        "Mondi developed a side gusset bag that meets the highest demands for convenient packaging while still being recyclable where recycling streams are in place.",
    },
    3: {
      img: "/adult_diapers.jpg",
      modal: {
        type: "pdf",
        url: "/f5.pdf",
        invis: true,
      },
      name: "Adult diapers",
      sub: "Premium recyclable and reclosable plastic bag with convenience",
      teaser:
        "Mondi developed a side gusset bag that meets the highest demands for convenient packaging while still being recyclable where recycling streams are in place.",
    },
    4: {
      img: "/baby_diapers.png",
      modal: {
        type: "pdf",
        url: "/f5.pdf",
        invis: true,
      },
      name: "Baby diapers",
      sub: "Premium recyclable and reclosable plastic bag with convenience",
      teaser:
        "Mondi developed a side gusset bag that meets the highest demands for convenient packaging while still being recyclable where recycling streams are in place.",
    },
  },
};

export default function TourController({ floating = false }) {
  let { building, room, point, selectPoint, selectedPoint, mode } = useTour();

  let [selectedIcon, setSelectedIcon] = useState(0);

  let points = building.points || [];

  let onKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        selectedPoint > 0 && selectPoint(selectedPoint - 1);
      }
      if (e.key === "ArrowRight") {
        selectPoint((selectedPoint + 1) % points.length);
      }
      if (e.key === "Escape") {
        selectPoint(-1);
      }
    },
    [selectedPoint, selectPoint, points.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const TheGuide = (
    <Guide>
      {mode === "explore" && (
        <div>
          „Welcome to the interactive guided tour by Mondi Gronau. We will give
          you the opportunity to take a tour through our digital production
          facilities. <br />
          <br />
          Click on the <span className="i-icon">i</span> icons for more
          information or press the button below to start the guided tour.“
          <Spacer h={24}></Spacer>
        </div>
      )}
      {selectedPoint === 0 && mode === "tour" && (
        <div>
          „Welcome to the <b>interactive Guided Tour</b> by Mondi Gronau. We
          will give you the opportunity to take a tour through our digital
          production facilities.
          <br />
          <img
            alt="tutorial"
            style={{ width: "100%", marginBottom: 12, marginTop: 12 }}
            src="/tutorial.png"
          ></img>
          <br />
          Use the <b>on-screen buttons</b> or the <b>arrow keys</b> on your
          keyboard to navigate.“
          <Spacer h={24}></Spacer>
        </div>
      )}
      <div className="buttons">
        {mode === "tour" ? (
          <div
            className={"startTourButton blue"}
            onClick={() => selectPoint(-1, "explore")}
          >
            Exit Tour
          </div>
        ) : (
          <div
            className="startTourButton"
            onClick={() => {
              setCurrentHover(null);
              selectPoint(0, "tour");
            }}
          >
            Start Guided tour
          </div>
        )}
        {mode === "tour" && (
          <>
            <div
              className={
                "leftButton " + (selectedPoint > 0 ? " " : " disabled")
              }
              onClick={() =>
                selectedPoint > 0 && selectPoint(selectedPoint - 1)
              }
            >
              <FaChevronLeft></FaChevronLeft>
            </div>
            <div
              className={"rightButton"}
              onClick={() =>
                selectedPoint + 1 >= points.length
                  ? selectPoint(-1, "explore")
                  : selectPoint((selectedPoint + 1) % points.length)
              }
            >
              <FaChevronRight></FaChevronRight>
            </div>
          </>
        )}
      </div>
    </Guide>
  );
  let { currentHover, setCurrentHover } = useHoverState();
  let [modal, setModal] = useState(false);
  let [open, setOpen] = useState(true);
  let [mouseOver, setMouseOver] = useState(null);
  useEffect(() => {
    setCurrentHover(null);
  }, [setCurrentHover]);

  let style = useSpring({ tension: 1, damping: 1, left: open ? 0 : 360 });
  let rotstyle = useSpring({ tension: 1, damping: 1, rotate: open ? 180 : 0 });

  return (
    <>
      {modal && <Modal onClose={() => setModal(false)} {...modal}></Modal>}
      <div className={"floatTourController"}>
        <div className="flex1"></div>
        {currentHover && (
          <InfoBox
            {...regalData[building.id][currentHover]}
            onClose={() => {
              setCurrentHover(null);
            }}
            setModal={setModal}
            modal={
              regalData[building.id][currentHover] &&
              regalData[building.id][currentHover].modal
            }
          ></InfoBox>
        )}
        {!point && building.regal && building.id === 2 && (
          <animated.div className="regal" style={style}>
            <animated.div
              style={rotstyle}
              className="regalButton"
              onClick={() => {
                setCurrentHover(null);
                setOpen(!open);
              }}
            >
              <FaChevronLeft></FaChevronLeft>
            </animated.div>
            <div className="name">See how our products are made.</div>
            <div className="text">
              Choose a product and follow the production line path.
            </div>
            <div className="icons">
              <div
                className={"icon " + (currentHover === 1 ? "active" : " ")}
                onClick={() => setCurrentHover(1)}
                onPointerEnter={() => {
                  setMouseOver(1);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/icon1.jpeg" alt="" />
              </div>
              <div
                className={"icon " + (currentHover === 2 ? "active" : " ")}
                onClick={() => setCurrentHover(2)}
                onPointerEnter={() => {
                  setMouseOver(2);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/icon2.jpeg" alt="" />
              </div>
              <div
                className={"icon " + (currentHover === 3 ? "active" : " ")}
                onClick={() => setCurrentHover(3)}
                onPointerEnter={() => {
                  setMouseOver(3);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/icon3.jpeg" alt="" />
              </div>

              <div
                className={"icon " + (currentHover === 5 ? "active" : " ")}
                onClick={() => setCurrentHover(5)}
                onPointerEnter={() => {
                  setMouseOver(5);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/icon5.jpeg" alt="" />
              </div>
              <div
                className={"icon " + (currentHover === 6 ? "active" : " ")}
                onClick={() => setCurrentHover(6)}
                onPointerEnter={() => {
                  setMouseOver(6);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/icon6.jpeg" alt="" />
              </div>
            </div>
            <div className="regalName">
              {mouseOver && regalData[building.id][mouseOver].name} &nbsp;
            </div>
          </animated.div>
        )}

        {!point && building.regal && building.id === 1 && (
          <animated.div className="regal" style={style}>
            <animated.div
              style={rotstyle}
              className="regalButton"
              onClick={() => {
                setCurrentHover(null);
                setOpen(!open);
              }}
            >
              <FaChevronLeft></FaChevronLeft>
            </animated.div>
            <div className="name">See our products.</div>
            <div className="text">Choose a product for more information</div>
            <div className="icons">
              <div
                className={"icon " + (currentHover === 1 ? "active" : " ")}
                onClick={() => setCurrentHover(1)}
                onPointerEnter={() => {
                  setMouseOver(1);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/inco_pads.jpg" alt="" />
              </div>
              <div
                className={"icon " + (currentHover === 2 ? "active" : " ")}
                onClick={() => setCurrentHover(2)}
                onPointerEnter={() => {
                  setMouseOver(2);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/360_pants.png" alt="" />
              </div>
              <div
                className={"icon " + (currentHover === 3 ? "active" : " ")}
                onClick={() => setCurrentHover(3)}
                onPointerEnter={() => {
                  setMouseOver(3);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/adult_diapers.jpg" alt="" />
              </div>
              <div
                className={"icon " + (currentHover === 4 ? "active" : " ")}
                onClick={() => setCurrentHover(4)}
                onPointerEnter={() => {
                  setMouseOver(4);
                }}
                onPointerLeave={() => {
                  setMouseOver(null);
                }}
              >
                <img src="/baby_diapers.png" alt="" />
              </div>
            </div>
            <div className="regalName">
              {mouseOver && regalData[building.id][mouseOver].name} &nbsp;
            </div>
          </animated.div>
        )}

        {!floating && (
          <>
            {room && (
              <InfoBox
                label={"Current room"}
                img={room.img}
                name={room.name}
                teaser={room.description}
              ></InfoBox>
            )}

            <InfoBox
              label={"Current Building"}
              name={building.name}
              text={building.description}
              teaser={building.teaser}
              img={building.img}
              sub={building.sub}
            ></InfoBox>
            {!point && building.regal && (
              <div className="regal">
                <div className="name">See how our products are made.</div>
                <div className="text">
                  Choose a product and follow the production line path.
                </div>
                <div className="icons">
                  <div
                    className={"icon " + (selectedIcon === 1 ? "active" : " ")}
                    onClick={() => setSelectedIcon(1)}
                  >
                    <img src="/icon1.png" alt="" />
                  </div>
                  <div
                    className={"icon " + (selectedIcon === 2 ? "active" : " ")}
                    onClick={() => setSelectedIcon(2)}
                  >
                    <img src="/icon2.png" alt="" />
                  </div>
                  <div
                    className={"icon " + (selectedIcon === 3 ? "active" : " ")}
                    onClick={() => setSelectedIcon(3)}
                  >
                    <img src="/icon3.png" alt="" />
                  </div>
                  <div
                    className={"icon " + (selectedIcon === 4 ? "active" : " ")}
                    onClick={() => setSelectedIcon(4)}
                  >
                    <img src="/icon1.png" alt="" />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {TheGuide}
      </div>
    </>
  );
  /*
  return (
    <div className={"tourController"}>
      <div className="scrollBox">
        <InfoBox label={""} name="interactive tour" noImage></InfoBox>
        <InfoBox
          label={"Current Building"}
          name={building.name}
          text={building.description}
        ></InfoBox>
        {room && (
          <InfoBox
            label={"Current room"}
            img={room.img}
            name={room.name}
            text={room.description}
          ></InfoBox>
        )}
      </div>
      <TourButtons></TourButtons>
    </div>
  );
  */
}
/*
function TourButtons() {
  let { building, selectPoint, selectedPoint } = useTour();
  let points = building.points || [];

  return (
    <div className="tourButtons">
      {(selectedPoint === -1 && (
        <div className="startButton" onClick={() => selectPoint(0)}>
          Start Guided Tour
        </div>
      )) || <div className="flex1"></div>}
      <div
        className={"tourButton " + (selectedPoint > 0 ? " " : "disabled")}
        onClick={() => selectedPoint > 0 && selectPoint(selectedPoint - 1)}
      >
        <FaChevronLeft style={{ paddingLeft: 12 }}></FaChevronLeft>
      </div>
      <div
        className={
          "tourButton " + (selectedPoint < points.length - 1 ? " " : "disabled")
        }
      >
        <FaChevronRight
          style={{ paddingLeft: 12 }}
          onClick={() =>
            selectedPoint < points.length - 1 &&
            selectPoint((selectedPoint + 1) % points.length)
          }
        ></FaChevronRight>
      </div>
    </div>
  );
}

*/
