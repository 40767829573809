import { useNavigate } from "@reach/router";
import React, { useState } from "react";
import { Input, RoundButton } from "../atoms/form/form";
import Layout from "../atoms/layout/layout";
import Spacer from "../atoms/spacer/spacer";

export default function Password() {
  let [pw, setPw] = useState("");
  let [error, setError] = useState(false);
  let nav = useNavigate();
  return (
    <Layout
      noMember
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Diese Seite ist Passwortgeschützt
      <Spacer h={12}></Spacer>
      <Input
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (pw === "mondiexperience") {
              nav("/home");
            } else {
              setError(true);
            }
          }
        }}
        value={pw}
        onChange={(s) => {
          setPw(s);
        }}
        type="password"
      />
      <Spacer h={12}></Spacer>
      <RoundButton
        onClick={() => {
          if (pw === "mondiexperience") {
            nav("/home");
          } else {
            setError(true);
          }
        }}
      >
        Abschicken
      </RoundButton>
      <Spacer h={12}></Spacer>
      {error && <div className="error">Passwort falsch.</div>}
    </Layout>
  );
}
