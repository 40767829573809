import create from "zustand";

const useTourState = create((set, get) => {
  return {
    buildings: [
      {
        name: "Cutting Edge Analysis",

        id: 0,
        model: "Raum5",
        img: "/analytic.png",
        scale: 100,
        camera: {
          position: [3.07, 6.0, 1.37],
          theta: 2.299666548323477,
          phi: 0.8607963870836051,
          zoom: 24.531242609082256,
        },
        rooms: {
          0: {
            id: 0,
            name: "Microscopy",
            description:
              "Surface and cross section evaluation / Determination of surface tension. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            img: "/img.png",
          },
          1: {
            id: 1,
            name: "Infrared Spectroscopy",
            description:
              "Chemical structure determination. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            img: "/img.png",
          },
          2: {
            id: 2,
            name: "Rheology / Chemical laboratory",
            description:
              "Evaluation of melt flow properties / Preparation of hand coated samples. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            img: "/img.png",
          },
          3: {
            id: 3,
            name: "Permeation analysis",
            description: "Permeation measurements on barrier films.",
            img: "/img.png",
          },
          4: {
            id: 4,
            name: "Physical test laboratory",
            description:
              "Physical and mechanical measurements on films, papers and laminates. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            img: "/img.png",
          },
          5: {
            id: 5,
            name: "Thermal analysis",
            description:
              "Thermal analysis on films and raw materials. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
            img: "/img.png",
          },
          6: {
            id: 6,
            name: "HPLC and UV/Vis laboratory",
            description:
              "Quantitative analysis of additives in polymeric materials / Evaluation of UV transmission properties. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ",
            img: "/img.png",
          },
        },

        points: [
          {
            name: "Analytical Lab",
            description: `The R&D center in Gronau has a modern analytical laboratory, where detailed material and product analyses are done with state-of-the-art equipment. In addition, tailor-made test methods are developed together with our customers.`,
            img: "/analyticpreview.png",
            isRoom: true,
            room: 0,
            position: [-1.64, 3.0, -0.26],
            theta: 2.3027874150813163,
            phi: 0.9770353152664258,
            zoom: 63.22656249999909,
            textModal: {
              label: "Factsheet",
              type: "pdf",
              url: "/analytic_facts.pdf",
              title: "Our mask production",
              text: "Polymer Analytical Services of Mondi Flexible Packaging R&D.",
              invis: true,
            },
            modal: {
              type: "vimeo",
              id: "641907135?h=d345e487ea",
              title: "",
              text: "",
              invis: true,
            },
            offset: { left: 0, top: 150 },
          },
          {
            name: "Microscopy",
            description:
              "Surface and cross section evaluation / Determination of surface tension.",

            img: "/img.png",
            isRoom: true,
            room: 1,
            position: [16.03, 3.0, 17.43],
            theta: 2.3436281195779873,
            phi: 0.8953539062730926,
            zoom: 81.67968749999909,
          },
          {
            name: "Microscopy Workstations",
            description: `Optical Microscope with CCD-Camera and Image Analysis Software and Stereomicroscope. Cross section of multilayer films, determination of layer calipers by image analysis software; phase contrast analysis for surface coverage of films by printing inks etc.`,
            room: 1,
            position: [15.44, 3.0, 14.26],
            theta: 2.0215040572894156,
            phi: 0.9005903414401386,
            zoom: 131.12339687344502,
            offset: { left: 0, top: 200 },
            isMachine: true,
            machine: 0,
          },
          {
            name: "Scanning Electron Microscope (SEM/EDX)",
            description: `Scanning Electron Microscope with attached Energy Dispersive X-ray Analyzer(SEM/EDX). Analysis of surface structures/topographies, elemental analysis of smallest particles (semi-quantitative element identification).`,
            room: 1,
            isMachine: true,
            machine: 1,
            position: [11.69, 3.0, 18.4],
            theta: 2.235132741228722,
            phi: -0.21991148575128416,

            offset: { left: 0, top: 200 },
            zoom: 136.75,
          },
          {
            name: "DSA30S CONTACT ANGLE METER",
            description: `Evaluation of surface tension of printable films, including dispersive and polar portions of surface tension.`,
            room: 1,
            isMachine: true,
            machine: 18888,
            position: [16.69, 3.0, 22.4],
            theta: 2.3215040572894156,
            phi: 0.9005903414401386,

            offset: { left: 0, top: 200 },
            zoom: 116.75,
          },

          {
            name: "Infrared Spectroscopy",
            description: "Chemical structure determination.",

            img: "/img.png",
            isRoom: true,
            room: 2,
            position: [-0.95, 3.0, 21.11],
            theta: 2.227389191395165,
            phi: 0.8450884238156556,
            zoom: 69.67968761920838,
          },

          {
            name: "FT- Infrared-Spectrometer",
            description: `FT- Infrared-Spectrometer with attached FT-IR-Microscope. Suitable for the identification of chemical structures of polymeric materials as well as for identification of gels in multilayer films, contamination on surfaces, chemical structure of inorganics like fillers etc. and functionality-specific mapping of chemical substances on film surfaces down to 5 x 5 μm in size.`,
            img: "/img.png",
            room: 2,
            isMachine: true,
            machine: 2,
            position: [-2.36, 3.0, 14.83],
            theta: 2.2344341088536344,
            phi: 1.0088006059267656,
            zoom: 150,
          },

          {
            name: "Rheology / Chemical laboratory",
            description:
              "Evaluation of melt flow properties / Preparation of hand coated samples.",

            img: "/img.png",
            isRoom: true,
            room: 3,
            position: [-15.78, 3.0, 19.17],
            theta: 2.2242475987415737,
            phi: 0.4806636759992402,
            zoom: 77.67956554889588,
          },
          {
            name: "High pressure Capillary Rheometer (HPCR)",
            description: `Evaluation of flow characteristics of molten thermoplastic materials at elevated temperatures under VERY high shear stress; melt flow index and melt flow volume.`,
            img: "/img.png",
            room: 3,
            isMachine: true,
            machine: 3,

            offset: { left: -200, top: 0 },
            position: [-19.38, 3.0, 22.15],

            theta: 2.2588688888238644,
            phi: 0.4956736780087005,
            zoom: 139,
          },
          {
            name: "Melt Flow Index Rheometer (MFI)",
            description: `Evaluation of flow characteristics of molten thermoplastic materials at elevated temperatures under shear stress; melt flow index and melt flow volume.`,
            room: 3,
            isMachine: true,
            machine: 4,
            img: "/img.png",

            offset: { left: -150, top: 0 },
            position: [-13.75, 3.0, 13.56],
            theta: 2.2204716239747,
            phi: 1.0541790173415981,
            zoom: 150,
          },
          {
            name: "Permeation",
            description:
              "Permeation measurements on barrier films and coated papers.",
            img: "/img.png",
            isRoom: true,
            room: 4,
            position: [-20.98, 3.0, -1.04],
            theta: 2.2902210444669597,
            phi: 0.4586725274241118,
            zoom: 84.27233898639588,
          },
          {
            name: "Water Vapour Transmission Rate Analyser (WVTR)",
            description: `Evaluation of water vapour permeation through filmic samples in a temperature- and humidity-controlled environment (10-38°C / 50-100% r. h.); characterisation of barrier properties of films for food packaging.`,
            room: 4,
            img: "/img.png",

            offset: { left: -40, top: -40 },
            position: [-26.42, 1.5, 3.39],
            theta: 2.17230088815693,
            phi: 0.7822565707438565,
            zoom: 150,
            isMachine: true,
            machine: 5,
          },

          {
            name: "Oxygen Transmission Rate Analyzer (OTR)",
            description: `Evaluation of oxygen permeation through filmic samples in a temperatureand humidity- controlled environment (18 – 38 °C / 0 – 90% r.h.); characterisation of barrier properties of films for food packaging.`,
            img: "/img.png",
            room: 4,
            offset: { left: -150, top: -30 },
            position: [-20.77, 3.0, -8.37],
            theta: 2.1925467500906324,
            phi: 0.4712387062908705,
            zoom: 150,
            isMachine: true,
            machine: 6,
          },
          {
            name: "Physical test laboratory",
            description:
              "Physical and mechanical measurements on films, papers and laminates.",
            img: "/img.png",
            isRoom: true,
            room: 5,
            position: [-8.2, 3.0, -20.42],
            theta: 2.293362637120551,
            phi: 1.09013265079566,
            zoom: 52.65612804889588,
          },

          {
            name: "Bending stiffness tester",
            description: `Stiffness evaluation of films, laminates and papers at specific bending angles.`,
            img: "/img.png",
            room: 5,
            position: [-21.3, 3.0, -16.74],
            theta: 2.3391544711740235,
            phi: 0.23038378084258282,
            zoom: 146,
            isMachine: true,
            machine: 7,
          },
          {
            name: "Sealing device with Hot Tack attachment",
            description: `Thermo sealing of films and laminates under standardised temperature, time and pressure conditions for testing seams regarding green strength and peel force.`,
            img: "/img.png",
            room: 5,
            isMachine: true,
            machine: 88,
            position: [-21.03, 3.0, -21.58],
            theta: 2.4927435305706793,
            phi: 0.5515241927672292,
            zoom: 146,
          },
          {
            name: "Tear strength/ Puncture resistance Tester",
            description: `Measurement of tear resistance and Spencer impact energy of filmic samples or paper samples.`,
            img: "/img.png",
            room: 5,
            isMachine: true,
            machine: 8,
            position: [-20.48, 3.0, -25.33],
            theta: 2.150658720211037,
            phi: 0.6981319138506191,
            zoom: 146.00000000000003,
          },
          {
            name: "Tensile tester",
            description: `Characterization of the physical properties of films such as tear resistance, elasticity, hysteresis, modulus of elasticity, creep force, elongation, puncture resistance, coefficient of friction, etc. each at different speeds and angles and load cells.`,
            img: "/img.png",
            room: 5,
            isMachine: true,
            machine: 9,
            offset: { left: 300, top: 0 },
            position: [-14.01, 3.0, -29.04],
            theta: 2.209999424757202,
            phi: 1.270600121557645,
            zoom: 150,
          },
          {
            name: "Hazemeter",
            description: `Determination of turbidity, clarity and permeability by means of transmitted light testing.`,
            img: "/img.png",
            room: 5,
            isMachine: true,
            machine: 10,

            offset: { left: 0, top: -100 },
            position: [-11.93, 1.5, -29.88],
            theta: 2.285398223686151,
            phi: 1.463982176572843,
            zoom: 150,
          },

          {
            name: "Surface resistance meter",
            description: `Surface and bulk resistance measurement of plain films, evaluation of antistatic properties.`,
            img: "/img.png",
            room: 5,
            isMachine: true,
            machine: 11,
            position: [-8.78, 1.5, -30.21],
            theta: 2.3293805208364087,
            phi: 1.6744688843633593,
            zoom: 150,
          },
          {
            name: "Tensile tester (thermochamber)",
            room: 5,
            isMachine: true,
            machine: 12,
            description: `Characterization of the physical properties of films such as tear resistance, Elasticity, hysteresis, elastic modulus, creep force, elongation, puncture resistance, etc. each with different starting speeds and angles, but optionally in a temperature-controlled chamber at temperatures from -60 ° C to 200 ° C.`,
            img: "/img.png",

            offset: { left: -150, top: 0 },
            position: [-4.67, 3.0, -28.97],
            theta: 2.2867942420768945,
            phi: 1.2322036236989256,
            zoom: 150,
          },
          {
            name: "Thickness measurement",
            description: `Measurement of thickness of parts.`,
            img: "/img.png",
            room: 5,
            isMachine: true,
            machine: 13,
            position: [2.09, 3.0, -27.3],
            theta: 2.185565124155965,
            phi: 1.9722233756814902,
            zoom: 150,
          },
          {
            name: "Ultrasonic sealer",
            description: `Ultrasonic sealing of films with 20 kHz and various anvil geometries for permanent or peelable sealing.`,
            img: "/img.png",
            isMachine: true,
            machine: 14,
            position: [-4.08, 3.0, -20.29],
            theta: 2.1995278007824965,
            phi: 0.8028525637924018,
            zoom: 150,
            room: 5,
          },
          {
            name: "Optical Microscope with CCD-Camera",
            description: `Optical Microscope with CCD-Camera and Image Analysis Software. Stereomicroscope"Cross section of multilayer films, determination of layer calipers by image analysis software; phase contrast analysis for surface coverage of films by printing inks etc.`,
            img: "/img.png",
            room: 5,
            isMachine: true,
            machine: 15,
            position: [-15.99, 3, -19.92],

            offset: { left: 250, top: -50 },
            theta: 2.055098268938969,
            phi: 1.248343122895492,
            zoom: 74.53124260908226,
          },
          {
            name: "Thermal analysis",
            description: "Thermal analysis on films and raw materials.",
            img: "/img.png",
            isRoom: true,
            room: 6,
            position: [19.94, 3.0, -19.35],
            theta: 2.268229895891832,
            phi: 0.4838052686528304,
            zoom: 64.53015148639588,
          },

          {
            name: "Differential Scanning Calorimeter (DSC deep temperature)",
            description: `A differentiation of thermoplastic and duromeric materials and mixtures according to their melting point and glass transition temperature resp., in a temperature range from -130°C to +400°C; evaluation of melt enthalpy, crystalline moiety, reaction enthalpy with controlled heating/cooling rates up to 300K/min.`,
            img: "/img.png",
            room: 6,
            isMachine: true,
            machine: 16,
            position: [9.92, 1.5, -15.5],
            theta: 2.081194701202828,
            phi: -0.895353906273093,
            zoom: 132.43697178270668,
          },
          {
            name: "Differential Scanning Calorimeter (DSC incl. autosample function)",
            description: `A differentiation of thermoplastic and duromeric materials and mixtures according to their melting point and glass transition temperature resp., in a temperature range from +20°C to +400°C; evaluation of melt enthalpy, crystalline moiety, reaction enthalpy with controlled heating/cooling rates up to 300K/min.`,
            img: "/img.png",
            room: 6,
            isMachine: true,
            machine: 17,
            position: [10.23, 1.5, -20.73],
            theta: 2.2037168146928297,
            phi: 0.37699111843077326,
            zoom: 132.43697178270668,
          },
          {
            name: "Differential Scanning Calorimeter (DSC7)",
            description: `A differentiation of thermoplastic and duromeric materials and mixtures according to their melting point and glass transition temperature resp., in a temperature range from +20°C to +400°C; evaluation of melt enthalpy, crystalline moiety, reaction enthalpy with controlled heating/cooling rates up to 300K/min.`,
            img: "/img.png",
            room: 6,
            isMachine: true,
            machine: 18,
            position: [10.65, 1.5, -23.77],
            theta: 2.511592837744462,
            phi: 0.4335397861422777,
            zoom: 132.43697178270668,
          },

          {
            name: "Dynamic Mechanical Thermal Analyzer (DMTA)",
            description: `Dynamic measurement of polymer melts, films, solid bars to determine the viscosity and the storage/loss module (temperature range: -100 °C to 270 °C).`,
            img: "/img.png",
            room: 6,
            isMachine: true,
            machine: 19,

            offset: { left: -100, top: -20 },
            position: [12.13, 3.0, -26.79],
            theta: 2.303908306635521,
            phi: 0.8028516050544124,
            zoom: 150,
          },
          {
            name: "Thermogravimetric Analyzer (TGA)",
            description: `Evaluation of dynamic weight loss of polymeric materials in a temperature range from 20°C to 800°C under inert or oxidative atmosphere; filler content of small film samples; degradation, thermal stability and life time forecasts for materials and compounds.`,
            img: "/img.png",
            room: 6,
            isMachine: true,
            machine: 20,

            offset: { left: 200, top: -20 },
            position: [16.83, 3.0, -25.28],
            theta: 2.3108898366963877,
            phi: 1.0611605474024604,
            zoom: 150,
          },
          {
            name: "Residual Moisture Analyzer",
            description: `Determination of moisture traces in thermoplastic granulates, fillers, batches etc. down to 1 ppm w/w water content by a coulometric Karl- Fischer method; differentiation of surface-adsorbed water and core- resident moisture in pellets.`,
            img: "/img.png",
            room: 6,
            isMachine: true,
            machine: 21,
            position: [20.31, 3.0, -27.29],
            theta: 2.488889182103517,
            phi: 1.1833338720108164,
            zoom: 150,
          },
          {
            name: "CO2 + Fibre Laser",
            description: `Sample preparation, sample bag prototyping, creating tear functions, template building, foaming of specially equipped films, laser activation of elastic films.`,
            img: "/img.png",
            room: 6,
            isMachine: true,
            machine: 22,

            offset: { left: -100, top: -20 },
            position: [24.51, 3.0, -27.11],
            theta: 2.4330386673449884,
            phi: 0.7923802687011081,
            zoom: 150,
          },

          {
            name: "HPLC, KFC and UV/Vis laboratory",
            description:
              "Quantitative analysis of additives in polymeric materials / Evaluation of UV transmission properties / Quantitative determination of water contents.",
            img: "/img.png",
            isRoom: true,
            room: 7,
            position: [26.83, 3.0, 0.57],
            theta: 2.309070600388497,
            phi: 0.9173450548482218,
            zoom: 68.53015148639588,
          },

          {
            name: "UV/Vis-Spectrophotometry",
            description: `Evaluation of UV-transmission properties of films from UV to visible light; high precision evaluation of migratable traces of Primary Aromatic Amines (PAA) derived from printing inks and lamination adhesives in food contact applications.`,
            img: "/img.png",
            room: 7,
            isMachine: true,
            machine: 23,

            offset: { left: -60, top: -20 },
            position: [20.9, 3.0, 4.58],
            theta: 2.5796461966807787,
            phi: 0.23387492936821172,
            zoom: 146.9994049035031,
          },
          {
            name: "Karl Fischer Coulometry (KFC)",
            description: `Selective determination of moisture in thermoplastic films, granulates, fillers, batches etc. down to 1 ppm w/w water content by a coulometric Karl- Fischer method; highly efficient by using an autosampler technique`,
            img: "/img.png",
            room: 7,
            isMachine: true,

            offset: { left: -30, top: -40 },
            machine: 24,
            position: [19.77, 3.0, -2.08],
            theta: 2.4539824860119195,
            phi: 0.6108660477989867,
            zoom: 146.9998931847531,
          },
          {
            name: "High Performance Liquid Chromatography (HPLC)",
            description: `Precision analysis of additives in granulates and films, i.e. slip agents, thermo-stabilizers, UV-stabilizers etc.`,
            img: "/img.png",
            room: 7,
            isMachine: true,
            machine: 25,
            position: [27.72, 3.0, -6.75],
            theta: 2.210000000000002,
            phi: 1.3163273218541014,
            zoom: 150,
          },
        ]
          .map((l, i) => ({ ...l, id: i }))
          .map((p) => {
            if (p.phi < -Math.PI) {
              p.phi += 2 * Math.PI;
            }
            return p;
          }),
        sub: "How we ensure quality",
        teaser:
          "Get insight into the analytical methods we can offer you and with which techniques we test your and all of our products in our Analytical Lab.",
        description:
          "Get insight into the analytical methods we can offer you and with which techniques we test your and all of our products in our Analytical Lab.",
      },
      {
        name: "Innovative Finishing",
        sub: "How we give Materials special Features",
        teaser:
          "Understand how Mondi achives the best performance for your products with a never heard of innovative finishing technologie called „Lite Film Perforation (LFP)“ in seconds",
        description: "3",
        id: 1,
        model: "Raum3",
        img: "/k2.jpeg",

        regal: true,
        scale: 10,
        camera: {
          position: [15.18, 1.5, 11.18],
          theta: 2.13460177631384,
          phi: 2.0954422999443856,
          zoom: 19.279347198032227,
        },
        rooms: {
          0: {
            id: 0,
            name: "Lite Film Perforation",
            description:
              "Coneserferum ea seces sandele ssimil essed ea vendign atendip sandand ipsunt quidistio mo odit autem et, quam, aruntur? Facepel iquias sum illabo. Nempor restrum eossitis molore s equia quat rentur.",
            img: "/img.png",
          },
        },
        points: [
          {
            name: "Lite Film Perforation".toUpperCase(),
            description:
              "Our Lite Film Perforation (LFP) innovation is a highly flexible offline laser perforation system. We have developed this unique technology together with one of the leading laser specialists in Germany. It´s designed for CNC like precise and focused perforation of super thin web like materials.",
            img: "/l01.jpg",
            room: 0,
            isRoom: true,
            position: [9.19, 1.5, 20.3],
            theta: 2.0340708108723176,
            phi: 2.001194520196254,
            zoom: 50,
            offset: { left: -100, top: 80 },
          },
          {
            name: "Technologies Served Available Inhouse".toUpperCase(),
            description:
              "This unique highspeed technology enables flexible perforation with versatile designs with nearly no limits for the substrates used. It can process inhouse pre-produced superthin substrates and components from our Cast fiilm & Blown film extrusion produced at Mondi Gronau to pure Nonwovens produced at Mondi Aschersleben.",
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 9990,
            position: [35.29, 1.5, 5.57],
            theta: 2.1744750432519977,
            phi: 1.9823452997683007,
            zoom: 50,
          },
          {
            name: "Raw materials supplier".toUpperCase(),
            description:
              "We work with a wide range of suppliers of raw materials, whose products we take to a new level with our production lines.",
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 0,
            position: [35.35, 2.5, 19.77],
            offset: { top: -50, left: -50 },
            theta: 1.9932301069022977,
            phi: 2.098583892597975,
            zoom: 60.279347198032227,
          },
          {
            name: "ALL SUBSTRATES POSSIBLE!",
            description:
              "Extra functionality and comfort for superthin elastic films ~ 30µm to 50µm with cross direction (CD) and machine direction (MD) stretch properties. Spectacular appearance and performance for Topsheet Nonwovens down to 20 g/m² and below.",
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 1,
            position: [27.33, 1.5, 8.36],
            theta: 2.162876110196151,
            phi: 1.8723892215395148,
            zoom: 50,
          },
          {
            name: "HERE WE GO: UNWINDERS",
            description:
              "Unwinding capabilities from one jumbo mother roll of ~ 2.000mm to multiple small ups in parallel.",
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 2,

            offset: { left: 0, top: -80 },
            position: [19.05, 1.5, 10.18],
            theta: 1.7550581142010957,
            phi: 1.7915913855162562,
            zoom: 100,
          },
          {
            name: "LASER TECHNOLOGY",
            description:
              "The laser technology offers full flexiblity for the pattern orientation with exact and automated positioning of the individual laser modules across the web. This enables exact but flexible distances of the perforation holes, hole shapes, hole orientation and even very small unperforated areas across or along the web if required for further processing. This innovative technology and it´s digital control enables to improve functionality while keeping the performance of even our thinnest elastic films.",
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 3,
            position: [10.6, 1.5, 9.76],

            offset: { left: -100, top: 0 },
            theta: 1.8455752521835802,
            phi: 1.0524335389525763,
            zoom: 100,
          },
          {
            name: "REWINDING WITH ROLL CUTTING",
            description: "Inline slitting from 60mm to 2.000mm width.",
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 4,
            position: [3.84, 1.5, 11.25],
            theta: 1.9115486979089653,
            phi: 1.8189821464284859,
            zoom: 100,
          },
          {
            name: "VARIOUS PRODUCTS POSSIBLE",
            description: `Explore our standard product range available for Baby Care and Light Inco Hygiene applications:
 
Premium Stretch Ear Breeze - our breathable stretch ear range for open diapers
 
MD Elastic Film Breeze - our supersoft and thin elastic film for discreet 360° fit in pants
 
Waistband Breeze - our super gentle elastic film for the extra snug in the back of open diapers
 
Topsheets Breeze - our supersoft ATB Carded topsheets (in development)
 
All of them super thin and thus sustainable by design`,
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 5,
            offset: { left: 0, top: 250 },
            position: [-1.22, 1.5, 9.04],
            theta: 2.28853935474666,
            phi: 2.0420347873553446,
            zoom: 100,
          },
          {
            name: "Our Customers Converting".toUpperCase(),
            description:
              "All our perforation and finishing patterns are designed to enable best possible converting on our customers line. No matter if the materials are pre-stretched in their process ~ 300% like our MD Elastic Films or ~ 180% like our Waistband Films. Our Lite Film perforation ensures nearly no loss in performance during converting but the extra comfort like breathability for the consumer.",
            img: "/l01.jpg",
            room: 0,
            isMachine: true,
            machine: 6,
            position: [-21.2, 1.5, 12.1],
            theta: 2.0780531085492226,
            phi: 1.9352210746113057,
            zoom: 40.279347198032227,
          },
        ]
          .map((l, i) => ({ ...l, id: i }))
          .map((p) => {
            if (p.phi < -Math.PI) {
              p.phi += 2 * Math.PI;
            }
            return p;
          }),
      },
      {
        name: "Flexible Production",
        sub: "How we ensure high demand Production",
        teaser:
          "See the different processes we can test in our Pilot Plant to provide your product efficiently, cost effectively and with the highest quality.",
        description: "4",
        id: 2,
        model: "Pilot",
        img: "/imgpilot.png",
        scale: 1,
        regal: true,
        camera: {
          position: [36.32, 1.5, 47.72],
          theta: 2.309967418722923,
          phi: -2.4883223434391892,
          zoom: 11.640374573536304,
        },
        rooms: {
          0: {
            id: 0,
            name: "Lite Film Perforation",
            description:
              "Coneserferum ea seces sandele ssimil essed ea vendign atendip sandand ipsunt quidistio mo odit autem et, quam, aruntur? Facepel iquias sum illabo. Nempor restrum eossitis molore s equia quat rentur.",
            img: "/img.png",
          },
        },
        points: [
          {
            position: [29.96, 1.5, 39.61],
            theta: 2.2131415926535865,
            phi: -2.378185638767474,
            zoom: 77.81995782489186,
            isRoom: true,
            img: "/pilotpreview.png",
            room: 0,
            name: "Pilot Plant Mondi Gronau",
            description:
              "The Pilot Plant in Gronau is equipped with modern extrusion and processing/finishing systems. New products and process technologies are developed and optimized here.",
            modal: {
              type: "vimeo",
              id: "641898695?h=ba60091d66",
              title: "",
              text: "",
              invis: true,
            },
            offset: { left: 0, top: 150 },
          },
          {
            position: [11.09, 1.5, 35.69],
            theta: 2.1845815548495375,
            phi: -2.3953213546537477,
            zoom: 100.00000000000028,
            isRoom: true,
            room: 1,
            name: "Custom Mixed Raw Material",
            description:
              "Different materials (pellets, powder, flakes, liquids) are processed into blends or pellets and are prepared for further processing in extrusion.",
          },
          {
            room: 1,
            position: [17.22, 1.5, 29.96],
            theta: 2.3302374403213766,
            phi: -2.6866325513331484,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 0,
            name: "M1804 - Dry Blend Mixer",
            description:
              "The heating-cooling mixer is a process that offers the possibility to mix solid, powder and liquid raw materials and to diffuse them at a temperature below the melting point.",
          },
          {
            room: 1,
            position: [14.66, 1.5, 41.78],
            theta: 2.353085509446804,
            phi: -2.19825755400003,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 1,
            offset: { left: 300, top: 100 },
            name: "M1802 - Compounder",
            description:
              "The compounder process can be used to homogenize polymers and additives that cannot be mixed in the single-screw extruder. After compounding the raw materials, granules or film are created from the melt.",
          },
          {
            machine: 1,
            room: 1,
            position: [16.83, 3.5, 42.23],
            theta: 2.191614089771594,
            phi: 4.079885750077393,
            zoom: 100.00000000000028,
            name: "Dosing Units",
            offset: { left: 70, top: -100 },
            description:
              "With gravimetric dosing, two raw materials can be dosed directly or several in one premix.",
          },
          {
            machine: 1,
            room: 1,
            position: [15.56, 2, 41.97],
            theta: 2.1474539467237457,
            phi: -2.346769903979179,
            zoom: 131.7478800528378,

            offset: { left: 320, top: 0 },
            name: "Melting and homogenizing",
            description:
              "The prepared granules, blend, powder or mixture is fed into the feeder of the compunder in a metered manner. The twin screw melts and homogenizes the polymer.",
          },
          {
            machine: 1,
            room: 1,
            position: [14.32, 1.7, 42.45],
            theta: 2.2245660104451654,
            phi: -1.9098028212079963,
            zoom: 131.7478800528378,
            name: "Cooling",

            offset: { left: 170, top: -30 },
            description:
              "A nozzle is used to create 4 strands which are cooled either in a water bath or with compressed air. Alternatively, a cast film can be produced directly.",
          },
          {
            machine: 1,
            room: 1,
            position: [9.32, 1.7, 43],
            theta: 2.2245660104451654,
            phi: -1.9098028212079963,
            zoom: 131.7478800528378,
            name: "Cutter Primo 60E",

            description:
              "Strand pelletizing systems for utmost flexibility during production. The machine pelletizers produce the highest quality of cylindrical pellets or microgranulate compounds particularly suitable for further processing. Variable system configurations allow for optimal matching with your specific production requirements and also provide utmost flexibility in terms of product changeover.",
          },
          {
            room: 1,
            position: [16.3, 1.5, 36.66],
            theta: 2.2102857203968456,
            phi: -2.592385199237827,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 2,
            name: "Granulat dryer",

            offset: { left: 70, top: -80 },
            description:
              "Drying is especially used where the polymer or components in the granules absorb water. Water has different effects depending on the polymer. The material, is dried in the container with dried and warm air. Drying time depends on humidity and polymer.",
          },

          {
            position: [35.59, 1.5, 21.88],
            theta: 2.1960055891459183,
            phi: -2.37818602226267,
            zoom: 33.824002233786956,
            isRoom: true,
            room: 2,
            name: "Tailored Feature Material Extrusion",
            description:
              "Film development in blown and cast extrusion, extrusion coating, extrusion lamination and embossing.",
          },
          {
            room: 2,

            position: [26.85, 3, 25.76],
            theta: 2.2331339642621018,
            phi: -2.8751286858781584,
            zoom: 81.74788005283779,
            isMachine: true,
            machine: 1339,
            name: "M1502 – Castfilm line",

            offset: { left: 20, top: -50 },
            description:
              "Cast Film Line production offers our customers the highest variance from various raw materials and basic ideas to productions.",
          },
          {
            room: 2,

            position: [26.85, 3, 25.76],
            theta: 2.2331339642621018,
            phi: -2.8751286858781584,
            zoom: 81.74788005283779,
            hideIcon: true,
            isMachine: true,
            machine: 1340,
            name: "Cast film (air knife)",
            offset: { left: 20, top: -50 },
            description:
              "The cast film is transferred to the chill roll at a small distance from the die. An air knife ensures uniform pressure.",
          },
          {
            isMachine: true,
            machine: 1337,
            room: 2,
            position: [26.85, 3, 25.76],
            theta: 2.2331339642621018,
            phi: -2.8751286858781584,
            zoom: 81.74788005283779,
            offset: { left: 20, top: -50 },
            hideIcon: true,
            name: "Embossing",
            description:
              "The melt is deposited between two rolls. The pressure of the rollers, the surface structure and the temperature of the rollers are parameters that determine the embossing pattern of the film.",
          },

          {
            isMachine: true,
            machine: 1338,
            hideIcon: true,
            room: 2,
            position: [26.85, 3, 25.76],
            theta: 2.2331339642621018,
            phi: -2.8751286858781584,
            zoom: 81.74788005283779,
            offset: { left: 20, top: -50 },
            name: "Coating",
            description: "The melt is deposited on a substrate as a coating.",
          },
          {
            isMachine: true,
            machine: 3,
            hideIcon: true,
            room: 2,
            position: [26.85, 3, 25.76],
            theta: 2.2331339642621018,
            phi: -2.8751286858781584,
            zoom: 81.74788005283779,
            offset: { left: 20, top: -50 },
            name: "Lamination film",
            description:
              "The melt is deposited between two liners as a polymeric connector or protected by the liner.",
          },
          {
            machine: 3,
            room: 2,
            position: [26.35, 1.5, 28.52],
            theta: 2.301677786545191,
            phi: -2.489569134261448,
            zoom: 131.7478800528378,
            name: "Melting",

            offset: { left: 70, top: -50 },
            description:
              "The five extruders have the task of melting the pellets. The polymer in the form of pellets is added to the extruder. At the appropriate temperature, pressure and shear, the screw produces a homogeneous melt.",
          },
          {
            machine: 3,
            room: 2,
            position: [29.46, 2, 26.65],
            theta: 2.139912426230913,
            phi: -2.6466892098695526,
            zoom: 131.7478800528378,
            name: "Extrusion Tool",

            offset: { left: -30, top: -80 },
            description:
              "In general, a distinction is made between mono and coextrusion. Both processes require a die, while coextrusion requires a feedblock. This feedblock is positioned between the extruders and the die. The melts from the extruders are placed on top of each other in the feedblock and transferred to the die as a single melt strand.",
          },
          {
            machine: 3,
            room: 2,
            position: [29.33, 2, 24.85],
            theta: 2.3216695829109097,
            phi: -2.5466892098695526,
            zoom: 131.7478800528378,
            name: "Cooling",
            description:
              "The melt is transferred from the die to the casting unit, where the melt is primarily converted from its liquid state to a solid state. Depending on the technical equipment of the casting unit, the production of a cast film, an embossed film or a laminating process takes place here.",
          },
          {
            machine: 3,
            room: 2,
            position: [28.33, 1.5, 19.85],
            theta: 2.2759736374729855,
            phi: -2.85513669776484,
            zoom: 109.50360451847094,
            name: "Thickness measurement",
            offset: { left: 70, top: -50 },
            description: "Thickness measurement over the web width.",
          },
          {
            machine: 3,
            room: 2,
            position: [28.37, 1.5, 16.18],
            theta: 2.281685414936677,
            phi: -2.349625600963429,
            zoom: 131.7478800528378,
            name: "Rewinder",
            offset: { left: 70, top: -150 },
            description:
              "At the end of the extrusion line the film is wound up. This winder offers the possibility of contact or center winding",
          },
          {
            room: 2,
            position: [43.72, 1.5, 22.81],
            theta: 2.118893813045893,
            phi: -2.4381617948550285,
            zoom: 100.00000000000027,
            isMachine: true,
            machine: 4,
            name: "M1006 - Blownfilm line",
            description:
              "The blown film line offers the possibility of mono to 5 layer extrusion. The maximum product width is 500mm. The successor is a Corona pre-treatment and an OCS inspection system.",
          },

          {
            machine: 4,
            room: 2,
            position: [43.61, 1.5, 26.13],
            theta: 2.1070838845531776,
            phi: 3.8680157077982225,
            zoom: 100.00000000000028,
            name: "Melting",

            offset: { left: 270, top: 0 },
            description:
              "The extruder has the task of melting the pellets. The polymer in the form of pellets is added to the extruder. At the appropriate temperature, pressure and shear, the screw produces a homogeneous melt.",
          },
          {
            machine: 4,
            room: 2,
            position: [45.63, 1.5, 25.07],
            theta: 2.0853866872013938,
            phi: 3.501536643524694,
            zoom: 100.00000000000028,
            name: "5 layer die",
            offset: { left: 420, top: 0 },
            description:
              "The melt is transferred directly from the extruder to the die. In the die, each stream is distributed individually and homogeneously via a distributor. Building up on the lowest layer, the other layers are built up one after the other within the die.",
          },
          {
            machine: 4,
            room: 2,
            position: [45.66, 3, 25.07],
            theta: 2.0676964372598166,
            phi: 3.3519992108841503,
            zoom: 100.00000000000028,
            name: "From melt to film",
            offset: { left: 420, top: 0 },
            description:
              "The melt is transferred from the die to the cooling ring. Depending on the technical design and setting, the diameter and thus the film width is adjusted via the internal pressure. Depending on the film type, the film is cooled to a certain temperature until it is laid flat.",
          },
          {
            machine: 4,
            room: 2,
            position: [45.66, 5, 25.08],
            theta: 2.2145591826491926,
            phi: 3.6519992108841503,
            zoom: 100.00000000000028,
            name: "Reversing unit",
            offset: { left: 420, top: 0 },
            description:
              "the reversing unit fixes the film tube. Places the tube over many roller guides flat so that two layers of film lie on top of each other. The second task of the reversing unit is the controlled rotation of the entire reversing device. With it in directly the foil is laid. In this way, the same areas of the film never lie on top of each other.",
          },
          {
            machine: 4,
            room: 2,
            position: [45.45, 3, 21.5],
            theta: 2.162058690183804,
            phi: 3.7448348168729995,
            zoom: 100.00000000000028,
            name: "Optical controlsystem",
            offset: { left: -30, top: -50 },
            description:
              "Using a camera, the film is scanned for differences in gray scale using transmitted light. With the recorded pixels, images are created in the software. These image data are then converted back into numerical values and qualitative trend values.",
          },

          {
            machine: 4,
            room: 2,
            position: [45.35, 3, 20.29],
            theta: 2.1161617932626666,
            phi: 3.4685671779459777,
            zoom: 100.00000000000028,
            name: "Corona pretreatment",

            offset: { left: -30, top: -50 },
            description:
              "Corona pretreatment is used to ensure the adhesion of coatings and inks to polyolefins, such as polypropylene and polyethylene, or to make it possible in the first place. ... Polar molecules are formed on the surface to which, for example, printing ink, coatings or adhesives can bond.",
          },

          {
            machine: 4,
            room: 2,
            position: [45.22, 1.5, 18.7],
            theta: 2.326741882133652,
            phi: 3.858701635514943,
            zoom: 127.81995782489186,
            name: "Rewinder",
            offset: { left: -30, top: -50 },
            description:
              "At the end of the extrusion line the film is wound up.",
          },

          {
            room: 2,
            position: [34.97, 1.5, 30.24],
            theta: 2.3759332904181667,
            phi: -1.9583552306210206,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 5,
            name: "M1002 - Blownfilm line",
            description:
              "This mono blownfilm line is mainly used for the first raw material screening.",
          },
          {
            machine: 5,
            room: 2,
            position: [33.19, 1.5, 31.29],
            theta: 2.056061959974096,
            phi: -1.7215927741672006,
            zoom: 100.00000000000028,
            name: "Melting",
            offset: { left: 300, top: -50 },
            description:
              "The extruder has the task of melting the pellets. The polymer in the form of pellets is added to the extruder. At the appropriate temperature, pressure and shear, the screw produces a homogeneous melt.",
          },
          {
            machine: 5,
            room: 2,
            position: [34.34, 2, 31.48],
            theta: 2.5430088212805133,
            phi: -1.2503538761287325,
            zoom: 100.00000000000028,
            name: "Mono layer die",
            offset: { left: 0, top: -50 },
            description:
              "The melt is transferred directly from the extruder to the die. In the die, the stream is distributed individually and homogeneously.",
          },
          {
            machine: 5,
            room: 2,
            position: [35.21, 2.5, 31.52],
            theta: 2.5053097094374355,
            phi: -1.9289378893041278,
            zoom: 100.2449625686385,
            name: "From melt to film",
            offset: { left: -50, top: -50 },
            description:
              "The melt is transferred from the die to the cooling ring. Depending on the technical design and setting, the diameter and thus the film width is adjusted via the internal pressure. Depending on the film type, the film is cooled to a certain temperature until it is laid flat.",
          },

          {
            machine: 5,
            room: 2,
            position: [38.02, 3.5, 31.08],
            theta: 2.320796939590194,
            phi: 5.049139180806874,
            zoom: 100.00000000000028,
            name: "Optical controlsystem",
            description:
              "Using a camera, the film is scanned for differences in gray scale using transmitted light. With the recorded pixels, images are created in the software. These image data are then converted back into numerical values and qualitative trend values.",
          },
          {
            machine: 5,
            room: 2,
            zoom: 126.56249999961238,

            position: [37.31, 1, 31.34],
            theta: 2.068628329285914,
            phi: -1.8912387796578276,
            offset: { left: -150, top: 0 },
            name: "Rewinder",
            description:
              "At the end of the extrusion line the film is wound up. This winder offers the possibility of contact or center winding.",
          },

          {
            position: [48.95, 1.5, 50.66],
            theta: 2.235989373624953,
            phi: -2.4752896906022115,
            zoom: 31.97573222593858,
            isRoom: true,
            room: 3,
            name: "Processing & finishing",
            description:
              "Foils, papers, aluminum foil and nonwovens are printed, laminated, coated and optimized for the customer's application.",
          },
          {
            room: 3,
            position: [48.31, 1.5, 36.64],
            theta: 2.387357324714547,
            phi: -2.2353864084852115,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 6,
            name: "M3001 - Slitting",
            description:
              "The M3001 slits or rewinds paper, film or nonwoven material in desired width. It is also possible to print eyemarks on the differnt sheets, with an inline thermal transfer printing unit.",
          },
          {
            machine: 6,
            room: 3,
            position: [49.65, 1.5, 39.91],
            theta: 2.1230539576401273,
            phi: 4.274058220739278,
            zoom: 80.75695928356228,
            name: "Slitting",
            offset: { left: -100, top: -50 },
            description:
              "Slitting is a web cutting process with circular knives, which is used to split wide webs into narrower widths or for edge trimming of rolled sheet.",
          },
          {
            machine: 6,
            room: 3,

            position: [44.92, 1.5, 38.95],
            theta: 2.0961096866012325,
            phi: -3.737427607143483 - Math.PI * 2,
            zoom: 110.91632104848523,
            offset: { left: -50, top: 0 },
            name: "Thermal Transfer Printing",
            description:
              "Depending on the requirements, the manufactured film or laminate can be printed with tactile marks. ",
          },
          {
            room: 3,

            position: [65.13, 1.5, 44.79],
            theta: 1.988227885750811,
            phi: -3.5218726268297913 - Math.PI * 2,
            zoom: 97.11980636179672,
            offset: { left: 0, top: 150 },
            isMachine: true,
            machine: 7,
            name: "M2001 – BHS",
            description:
              "Flexible finishingline for coating, flexo and rotoprinting and laminating.",
            modal: {
              type: "image",
              url: "/bhs.jpeg",
              title: "",
              text: "",
              invis: true,
            },
            img: "/bhs.jpeg",
          },
          {
            machine: 7,
            room: 3,

            position: [63.11, 1.5, 57.04],
            theta: 1.9159911062706874,
            phi: -2.4938723338666597 - Math.PI * 2,
            zoom: 120.36380976408739,
            name: "Roto Printing",
            offset: { left: 300, top: 0 },
            description:
              "The rotogravure process is a direct transfer method for printing on to differnt webs. The printing press uses a printing cylinder which has been laser engraved with minute cells capable of retaining ink, the size and pattern of which reflect the required image. These cells are forced to transfer their ink directly onto the substrate by a combination of pressure and capillary action, so producing the printed image.",
          },
          {
            machine: 7,
            room: 3,

            position: [62.82, 1.5, 44.7],
            theta: 1.9349680727954235,
            phi: -2.914391691134466 - Math.PI * 2,
            zoom: 120.36380976408739,

            name: "Flexo Printing",
            description:
              "Flexographic printing is a technique that uses a flexible printing plate. These printing plates were made of rubber, and nowadays businesses use flexible photopolymer printing plates. The plates are wrapped around cylinders on a web press. The inked plates have a raised image and rotate at high speeds to transfer the ink through small holes from the anilox roll to the substrate",
          },
          {
            machine: 7,
            room: 3,
            position: [63.95, 5, 37.49],
            theta: 2.2331337725145013,
            phi: -2.5409774324058625,
            zoom: 115.27939504623306,
            offset: { left: -50, top: -50 },
            name: "Lamination",
            description:
              "Laminating is the process through which two or more flexible packaging webs are joined together using a bonding agent. The substrates making up the webs may consist of films, papers, or aluminum foils.",
          },

          {
            room: 3,

            position: [58.96, 1.5, 47.73],
            theta: 2.2993873788725754,
            phi: 3.5091589940598 - Math.PI * 2,
            zoom: 120.640374573536306,

            offset: { left: -10, top: 150 },
            machine: 7,
            name: "ROTO PRINTING UNIT",
            description:
              "The Ink is pumped into the ink rail and from there over the gravure cylinder further promoted.",
            textModal: {
              label: "More info",
              type: "image",
              url: "/roto.png",
              title: "",
              text: "",
              invis: true,
            },
            modal: {
              type: "image",
              url: "/roto.png",
              title: "",
              text: "",
              invis: true,
            },
            img: "/ROTO.png",
          },
          {
            room: 3,

            offset: { left: -100, top: 150 },
            position: [58.96, 1.5, 50.8],
            theta: 2.2993873788725754,
            phi: 3.5091589940598 - Math.PI * 2,
            zoom: 120.640374573536306,

            machine: 7,
            name: "LAQUER UNIT",
            description: "The Ink is pumped into the Ink tray by the pump.",
            textModal: {
              label: "More info",
              type: "image",
              url: "/laquer.png",
              title: "",
              text: "",
              invis: true,
            },
            modal: {
              type: "image",
              url: "/laquer.png",
              title: "",
              text: "",
              invis: true,
            },
            img: "/LAQUER.png",
          },
          {
            room: 3,

            position: [58.96, 1.5, 53.8],
            theta: 2.2993873788725754,
            phi: 3.5091589940598 - Math.PI * 2,
            zoom: 120.640374573536306,
            offset: { left: -100, top: 150 },
            machine: 7,

            name: "SOLVENTFREE LAMINATION UNIT",
            description:
              "The preheated adhesive is transferred from the metering rollers to the rub roller.",
            textModal: {
              label: "More info",
              type: "image",
              url: "/solvent.png",
              title: "",
              text: "",
              invis: true,
            },
            modal: {
              type: "image",
              url: "/solvent.png",
              title: "",
              text: "",
              invis: true,
            },
            img: "/SOLVENT.png",
          },
          {
            room: 3,

            position: [58.96, 1.5, 56.8],
            theta: 2.2993873788725754,
            phi: 3.5091589940598 - Math.PI * 2,
            zoom: 120.640374573536306,
            offset: { left: -100, top: 150 },
            machine: 7,

            name: "FLEXO PRINTING UNIT",
            description:
              "The Ink is pumped into the chamber doctor, in this the cells of the anilox roller are filled with Ink and the excess Ink is wiped off with the doctor blades.",

            textModal: {
              label: "More info",
              type: "image",
              url: "/flexo.png",
              title: "",
              text: "",
              invis: true,
            },
            modal: {
              type: "image",
              url: "/flexo.png",
              title: "",
              text: "",
              invis: true,
            },
            img: "/FLEXO.png",
          },

          {
            room: 3,
            position: [53.18, 1.5, 60.98],
            theta: 2.1531134729668464,
            phi: 4.184169407242233,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 8,
            name: "M279 – PCC Lamination Line",
            description:
              "Flexible line for hotmelt and US laminating and activating.",
          },
          {
            machine: 8,
            room: 3,
            position: [51.05, 1.5, 63.33],
            theta: 2.0229874167112993,
            phi: 4.258728831058809,
            zoom: 100.00000000000028,
            name: "Hot Melt Lamination",
            offset: { left: 0, top: -50 },
            description:
              "Hot melt lamination is the application of a layer to a substrate by melting the desired material in order to bond it with another.",
          },
          {
            machine: 8,
            room: 3,
            position: [54.08, 1.5, 63.15],
            theta: 2.0872289981271615,
            phi: 4.363412665461685,
            zoom: 100.00000000000028,
            name: "Ultrasonic Bonding",
            offset: { left: 0, top: -50 },
            description:
              "Ultrasonic bonding is a process in which high-frequency (ultrasonic) sound waves are applied to two or more webs that are being held together under pressure to fuse them into a laminate.",
          },
          {
            machine: 8,
            room: 3,
            position: [60.39, 1.5, 63.16],
            theta: 2.3245023900255823,
            phi: 4.398981844980782,
            zoom: 100.00000000000028,
            offset: { left: 0, top: -50 },
            name: "MD Stretch",
            description:
              "The elastic film is drawn lengthways in the machine direction stretch unit, directly before laminating. After reduction of tension, the elastic film contracts and the laminate becomes elastic properties in MD.",
          },
          {
            machine: 8,
            room: 3,
            position: [47.62, 1.5, 62.22],

            zoom: 100.00000000000028,
            offset: { left: 0, top: -50 },
            theta: 1.8487168447150164,
            phi: -1.605354229378275,

            name: "Incremental Stretch",
            description:
              "The laminate is partially overstretched so that the elastic film is exposed. This is done with help of circular fin packages engage with each other from below and above.",
          },

          {
            position: [26.53, 1.5, 50.11],
            theta: 2.264549411429005,
            phi: -2.492425406488486,
            zoom: 99.99999999994455,
            isRoom: true,
            room: 4,
            name: "Testing & Evaluation",
            description:
              "Samples are created, products are evaluated and controlled in order to be optimized during production in the pilot plant.",
          },
          {
            room: 4,
            position: [35.52, 1.5, 50.6],
            theta: 2.4711095589687084,
            phi: -2.6797785335120956,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 9,
            name: "Spectrophotometer",
            description:
              "Measuring device for determination of colors and deviations",
          },
          {
            room: 4,
            position: [34.69, 1.5, 53.88],
            theta: 2.458757227477082,
            phi: -3.060768164614097,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 10,
            name: "M2002 - Manual coating",
            offset: { left: -30, top: -20 },
            description:
              "The manual lamination is needed to adjust and optimize the mixing ratios before the cascading, without burdening the large plant.",
          },
          {
            room: 4,
            position: [34.51, 1.5, 59.62],
            theta: 2.4501891777735367,
            phi: -2.703769034291318,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 11,
            name: "M4002 - Sealer",
            offset: { left: -30, top: -30 },
            description:
              "The sealing device is used to make the sealing seam, the quality of which can be used to confirm the product. After setting the parameters of time and temperature, 2 materials can be sealed together. The Mulktivac is specially designed for tray-film combinations.",
          },
          {
            room: 4,
            position: [28.81, 1.5, 60.41],
            theta: 2.0451778821825712,
            phi: -13.662786450443777,
            zoom: 79.18051201686637,
            isMachine: true,
            machine: 12,
            name: "M4001 - Deep drawing",
            offset: { left: -30, top: -20 },
            description:
              "With the thermoforming unit the formulation conditions can be tested.",
          },
          {
            room: 4,
            position: [27.3, 1.5, 54.83],
            theta: 2.3548706307858893,
            phi: -2.6483626069761965,
            zoom: 100.00000000000028,
            isMachine: true,
            machine: 13,
            name: "Microscope",
            description:
              "Digital images of products in top view or films and laminates in side view, for analysis of layers and their thicknesses",
          },
          {
            isRoom: true,
            position: [62.74, 1.5, 13.02],
            theta: 2.196005780893516,
            phi: -2.5124175863494,
            zoom: 100.00000000000028,
            name: "Intermediates Supplier",
            description:
              "We work with a wide range of suppliers of semi-finished products, whose products we take to a new level with our production lines or process into finished products.",
          },
          {
            isRoom: true,
            position: [74.07, 1.5, 2.18],
            theta: 1.856142558209929,
            phi: -2.195402432258576,
            zoom: 100.00000000000028,
            name: "Raw materials supplier",
            description:
              "We work with a wide range of suppliers of raw materials, whose products we take to a new level with our production lines.",
          },
          {
            isRoom: true,
            position: [7.61, 1.5, 77.19],
            theta: 2.215997577259235,
            phi: -2.4067460600667236,
            zoom: 100.00000000000028,
            name: "Quality Control",
            description:
              "All our productions are checked in quality control and the characteristics are improved to perfection in and through direct collaboration with the pilot plant.",
          },
          {
            isRoom: true,
            position: [5.09, 1.5, 88.48],
            theta: 2.3645091602479895,
            phi: -2.5752496311687954,
            zoom: 100,
            name: "Shipping Prototypes",
            description:
              "After the quality control, we coordinate the prototypes with our customers and give the product the final touch.",
          },
          {
            isRoom: true,
            position: [22.36, 1.5, 73.95],
            theta: 2.258837633965311,
            phi: -2.3353461573041945,
            zoom: 26.726495151703748,
            name: "Scale Up Production",
            description:
              "After the properties are 100% optimized to the satisfaction of all parties involved, we can expand production to our worldwide mass production.",
          },
        ]
          .map((l, i) => ({ ...l, id: i }))
          .map((p, index, arr) => {
            if (p.phi < -Math.PI) {
              p.phi += 2 * Math.PI;
            }
            if (p.phi > Math.PI) {
              p.phi -= 2 * Math.PI;
            }
            p.childCount = arr.filter((c) => c.machine === p.machine).length;
            return p;
          }),
      },
    ],
    fullSet: (d) => {
      set(d);
    },
    mode: "explore",
    selectedPoint: -1,
    tipHidden: false,
    selectPoint: (p, mode) => {
      let state = get();
      set({ selectedPoint: p, tipHidden: false, mode: mode || state.mode });
    },
    reset: () => {
      set({
        selectedPoint: -1,
        mode: "explore",
      });
    },
    //addPoint: (p) => set((state) => ({ points: [...state.points, p] })),
  };
});

export default useTourState;
