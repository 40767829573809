import { useFrame, useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef } from "react";
import "./factoryCanvas.scss";
import * as THREE from "three";

export default function Controller({
  target = [0, 0, 0],
  targetString = "0,0,0",
  zoom,
  targetTheta = 2.21,
  targetPhi = 0.75,
  debug = false,
  isVisible = true,
  doReset = true,
}) {
  if (debug) {
    target = [0, 1.5, 0];
  }
  let targetCube = useRef();
  const gl = useThree(({ gl }) => gl);
  const camera = useThree(({ camera }) => camera);

  let data = useMemo(() => {
    return {
      currentPosition: new THREE.Vector3(0, 0, 0),
      currentTarget: new THREE.Vector3(20, 120, 0),
      targetOffset: new THREE.Vector3(0, 0, 0),
      exploreOffset: new THREE.Vector3(target[0], target[1], target[2]),
      zoomOffset: 0,
      currentZoom: 20,
      dragStart: null,
      panStart: null,
      pinchStart: null,
      lastMouse: null,
      radius: 50,
      theta: 2.21,
      phi: 0.0,
      orbitVelocity: new THREE.Vector2(0, 0),
      zoomVelocity: 0,
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  data.target = [
    data.exploreOffset.x,
    data.exploreOffset.y,
    data.exploreOffset.z,
  ];
  useEffect(() => {
    if (!isVisible) {
      data.dragStart = null;
      data.pinchStart = null;
      data.panStart = null;
    }
  }, [isVisible, data]);

  const pointerdown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (data.pinchStart) {
        return;
      }
      if (e.touches && e.touches.length > 1) {
        return;
      }
      let pos = { clientX: e.clientX, clientY: e.clientY };
      if (e.touches) {
        pos.clientX = e.touches[0].clientX;
        pos.clientY = e.touches[0].clientY;
      }
      if (e.button && (e.button === 2 || e.button === 1)) {
        data.dragStart = new THREE.Vector2(pos.clientX, pos.clientY);
      } else {
        data.panStart = new THREE.Vector2(pos.clientX, pos.clientY);
      }
    },
    [data]
  );

  const pointermove = useCallback(
    (e) => {
      if (data.pinchStart) {
        return;
      }
      if (e.touches && e.touches.length > 1) {
        return;
      }
      let pos = { clientX: e.clientX, clientY: e.clientY };
      if (e.touches) {
        pos.clientX = e.touches[0].clientX;
        pos.clientY = e.touches[0].clientY;
      }
      if (data.dragStart) {
        let currentDrag = new THREE.Vector2(pos.clientX, pos.clientY);
        let delta = currentDrag.clone().sub(data.dragStart);
        data.orbitVelocity.add(delta);

        data.dragStart = currentDrag;
      }
      if (data.panStart) {
        let currentDrag = new THREE.Vector2(pos.clientX, pos.clientY);
        let delta = currentDrag.clone().sub(data.panStart).multiplyScalar(0.02);
        delta.rotateAround(new THREE.Vector2(0, 0), data.phi + Math.PI / 2);
        let offset = new THREE.Vector3(delta.x, 0, delta.y);

        data.targetOffset.add(offset);
        data.panStart = currentDrag;
      }
    },
    [data]
  );

  const touchmove = useCallback(
    (e) => {
      if (e.touches && e.touches.length === 1) {
        pointermove(e);
      }
      if (e.touches && e.touches.length > 1) {
        let f1 = new THREE.Vector2(e.touches[0].clientX, e.touches[0].clientY);
        let f2 = new THREE.Vector2(e.touches[1].clientX, e.touches[1].clientY);
        let distance = f1.distanceTo(f2);
        let totalDistance = distance - data.pinchStart;
        data.zoomVelocity = totalDistance * 0.01;
        data.pinchStart = distance;
      }
    },
    [data, pointermove]
  );
  const touchstart = useCallback(
    (e) => {
      if (e.touches && e.touches.length === 1) {
        pointerdown(e);
      }

      if (e.touches && e.touches.length > 1) {
        //pinch to zoom

        let f1 = new THREE.Vector2(e.touches[0].clientX, e.touches[0].clientY);
        let f2 = new THREE.Vector2(e.touches[1].clientX, e.touches[1].clientY);
        let distance = f1.distanceTo(f2);
        data.pinchStart = distance;
      }
    },
    [data, pointerdown]
  );
  const pointerup = useCallback(
    (e) => {
      data.dragStart = null;
      data.pinchStart = null;
      data.panStart = null;
    },
    [data]
  );
  const wheel = useCallback(
    (e) => {
      if (e.deltaY > 0) {
        data.zoomOffset -= data.currentZoom / 8;
      } else {
        data.zoomOffset += data.currentZoom / 8;
      }

      if (data.zoomOffset < -50) data.zoomOffset = -50;
      if (data.zoomOffset > 50) data.zoomOffset = 50;
      /*
      if (e.deltaY > 0) {
        data.zoomVelocity = (-0.5 * data.currentZoom) / 1600 * e.deltaY;
        if (debug) {
          data.zoomVelocity = -2;
        }
      } else {
        data.zoomVelocity = (0.5 * data.currentZoom) / 1600 * -e.deltaY;
        if (debug) {
          data.zoomVelocity = 2;
        }
      }*/
    },
    [data, debug]
  );

  const getOrbitPosition = () => {
    let pos = new THREE.Vector3();

    // Turn back into Cartesian coordinates
    pos.x = data.radius * Math.sin(data.theta) * Math.cos(data.phi);
    pos.y = data.radius * Math.sin(data.theta) * Math.sin(data.phi);
    pos.z = data.radius * Math.cos(data.theta);

    var quat = new THREE.Quaternion().setFromUnitVectors(
      camera.up,
      new THREE.Vector3(0, 0, 1)
    );
    pos.applyQuaternion(quat);
    pos.add(data.currentTarget.clone());
    return pos;
  };
  useEffect(() => {
    if (doReset) {
      data.targetOffset = new THREE.Vector3(0, 0, 0);
      data.zoomOffset = 0;
    }
  }, [targetString, data, doReset]);
  /*
  useEffect(() => {
    if (doReset === false) {
      console.log(data.currentPosition.clone());
      console.log(data.currentTarget.clone());
      console.log(data.targetOffset.clone());
      let targetv = data.currentPosition.clone();
      data.exploreOffset = targetv;
      data.currentPosition = new Vector3(0, 0, 0);
      console.log(data.currentPosition.clone());
      console.log(data.currentTarget.clone());
      console.log(data.targetOffset.clone());
    }
  }, [doReset, target, data]);
*/

  useEffect(() => {
    //subscribe
    gl.domElement.addEventListener("pointerdown", pointerdown, true);
    gl.domElement.addEventListener("touchstart", touchstart);
    gl.domElement.addEventListener("touchmove", touchmove);
    gl.domElement.addEventListener("touchend", pointerup);
    gl.domElement.addEventListener("pointermove", pointermove);
    gl.domElement.addEventListener("pointerup", pointerup);
    gl.domElement.addEventListener("wheel", wheel);
    return () => {
      //unsubscribe
      gl.domElement.removeEventListener("wheel", wheel);
      gl.domElement.removeEventListener("pointerdown", pointerdown);
      gl.domElement.removeEventListener("pointerup", pointerup);
      gl.domElement.removeEventListener("pointermove", pointermove);
      gl.domElement.removeEventListener("touchstart", touchstart);
      gl.domElement.removeEventListener("touchmove", touchmove);
      gl.domElement.removeEventListener("touchend", pointerup);
    };
  }, [
    gl.domElement,
    pointerdown,
    pointerup,
    pointermove,
    wheel,
    touchstart,
    touchmove,
  ]);

  useFrame(({ mouse }, delta) => {
    // move current Target closer to targetv
    let targetv = new THREE.Vector3(target[0], target[1], target[2]).add(
      data.targetOffset
    );
    if (delta > 0.06) {
      delta = 0.06;
    }
    delta *= 10;
    let decay = 0.02;
    let radPerPixel = Math.PI / 200000;
    if (debug) {
      decay = 0.5;
      radPerPixel = Math.PI / 2000;
    }
    let targetDelta = targetv.clone().sub(data.currentTarget);
    let td = targetDelta.multiplyScalar(0.04);
    if (td.length() > 0.5) {
      td.setLength(0.5);
    }
    data.currentTarget.add(td);
    var deltaPhi = radPerPixel * -data.orbitVelocity.x,
      deltaTheta = radPerPixel * data.orbitVelocity.y;
    // Subtract deltaTheta and deltaPhi
    data.theta = Math.min(Math.max(data.theta + deltaTheta, 0), Math.PI);
    data.phi -= deltaPhi;
    data.orbitVelocity.multiplyScalar(1 - decay);

    data.currentZoom += data.zoomVelocity;
    data.zoomVelocity = data.zoomVelocity * (1 - decay) * (1 - decay);

    if (!data.pinchStart) {
      camera.position.copy(getOrbitPosition());
      camera.lookAt(data.currentTarget);

      data.currentZoom +=
        (zoom + data.zoomOffset - data.currentZoom) * decay * 8 * delta;
    }
    if (data.currentZoom < 10) {
      data.currentZoom = 10;
      data.zoomOffset = 0;
      data.zoomVelocity = 0;
    }
    if (data.currentZoom > 150) {
      data.currentZoom = 150;
      data.zoomOffset = 0;
      data.zoomVelocity = 0;
    }
    camera.zoom = data.currentZoom;
    camera.updateProjectionMatrix();

    if (!data.dragStart && !debug) {
      data.phi += (data.phi - targetPhi) * -decay;
      data.theta += (data.theta - targetTheta) * -decay;
    }
    data.exploreOffset.copy(targetv);
    if (debug && targetCube && targetCube.current) {
      targetCube.current.position.copy(data.currentTarget);
    }
  });

  return (
    debug && (
      <>
        <mesh
          ref={targetCube}
          onPointerEnter={() => {
            //console.log("a");
            let debugText = `position: [${data.currentTarget.x.toFixed(
              2
            )},${data.currentTarget.y.toFixed(
              2
            )},${data.currentTarget.z.toFixed(2)}],
theta: ${data.theta},
pi: ${data.phi},
zoom: ${data.currentZoom}
`;

            navigator.clipboard.writeText(debugText);
          }}
        >
          <boxGeometry args={[1, 1, 1]}></boxGeometry>
          <meshNormalMaterial></meshNormalMaterial>
        </mesh>
      </>
    )
  );
}
